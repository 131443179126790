import React from "react";

function HealthInsurance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19652"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19652)">
                <path d="M15.381 12.915c-.3 0-.556.257-.556.556v2.223h-2.222c-.3 0-.556.256-.556.555 0 .3.256.556.556.556h2.222v2.223c0 .299.257.555.556.555.3 0 .556-.256.556-.555v-2.223h2.222c.3 0 .556-.257.556-.556 0-.3-.256-.555-.556-.555h-2.222V13.47c0-.3-.257-.556-.556-.556z"></path>
                <path d="M14.483 10.82c-.94.342-4.146 1.368-5.13 1.668l-.384.128v.513c.47 4.018 2.607 7.266 6.112 9.404l.3.17.299-.17c3.505-2.138 5.642-5.386 6.112-9.404v-.513l-.385-.128c-.983-.3-4.189-1.368-5.13-1.668a2.593 2.593 0 00-1.795 0zm.897 10.644c-2.949-1.88-4.744-4.659-5.257-8.079 1.283-.427 3.89-1.282 4.745-1.581a1.533 1.533 0 011.068 0c.855.299 3.463 1.154 4.745 1.538-.556 3.42-2.35 6.199-5.3 8.122z"></path>
                <path d="M25.341 9.026c0-2.052-1.496-3.59-3.59-3.59h-.428V6.46h.342c.3 0 .641 0 1.026.128 1.026.342 1.624 1.24 1.624 2.394v14.576c0 .684-.214 1.282-.684 1.795-.47.513-1.154.77-1.88.77H9.054c-1.496 0-2.564-1.026-2.564-2.565V9.282c0-.385 0-.812.128-1.154.3-.983 1.026-1.539 2.009-1.667.214-.043.47-.043.812-.043h.043V5.35h-.599c-.256 0-.512.042-.726.085-1.625.385-2.693 1.753-2.693 3.42V23.644c0 .3.043.641.128.983.3 1.283 1.496 2.565 3.334 2.565h13.166c1.624 0 3.077-1.41 3.206-3.035.042-.812.042-1.667.042-2.48V9.026z"></path>
                <path d="M19.356 4.708c-.64 0-1.197-.128-1.667-.556-.299-.299-.513-.64-.727-1.025-.256-.47-.64-.727-1.196-.727h-.813c-.47 0-.854.214-1.068.641-.128.171-.214.385-.342.556-.3.555-.812.897-1.41 1.026-.343.085-.727.042-1.07.042-.341 0-.512.171-.512.513v1.325c0 .47.128.642.641.642h8.549c.385 0 .556-.171.556-.556V5.563c-.043-.812-.086-.898-.94-.855zm-.17 1.368h-7.61v-.342c.214 0 .47 0 .727-.085a3.146 3.146 0 002.138-1.54l.128-.256c.043-.085.128-.213.17-.299.043-.085.086-.085.214-.085h.855c.086 0 .171 0 .3.213l.042.043c.214.385.47.812.855 1.197.556.556 1.325.855 2.223.855v.299h-.043z"></path>
            </g>
        </svg>
    );
}

export default HealthInsurance;
