import React from "react";

function LifeInsurance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19647"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19647)">
                <path d="M26.776 7.784l-.171-1.296a.461.461 0 00-.461-.401A15.734 15.734 0 0116.46 2.73l-.295-.23a.46.46 0 00-.572 0l-.295.23a15.735 15.735 0 01-9.716 3.357.461.461 0 00-.461.401l-.17 1.296a18.874 18.874 0 0010.453 19.335l.272.134a.46.46 0 00.406 0l.272-.134A18.874 18.874 0 0026.776 7.784zM15.934 26.289l-.069.037-.069-.037A17.94 17.94 0 015.873 7.904l.115-.922a16.65 16.65 0 009.878-3.528 16.647 16.647 0 009.877 3.555l.116.923a17.945 17.945 0 01-9.924 18.357z"></path>
                <path d="M20.938 12.543H17.71V9.315a.461.461 0 00-.46-.461h-2.768a.461.461 0 00-.46.46v3.229h-3.229a.461.461 0 00-.461.46v2.768a.461.461 0 00.461.46h3.228v3.229a.461.461 0 00.461.46h2.767a.46.46 0 00.461-.46v-3.228h3.228a.461.461 0 00.461-.461v-2.767a.461.461 0 00-.46-.461zm-.461 2.766h-3.228a.461.461 0 00-.461.462v3.227h-1.845V15.77a.461.461 0 00-.46-.46h-3.229v-1.845h3.228a.461.461 0 00.461-.461V9.776h1.845v3.228a.461.461 0 00.461.46h3.228v1.845z"></path>
            </g>
        </svg>
    );
}

export default LifeInsurance;
