import React from "react";

function EarplugLawyers() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19475"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19475)">
                <path
                    fill="#000"
                    d="M7.673 13.957c.943-.36 1.959-.005 2.265.793.305.798-.214 1.741-1.157 2.102l.437 1.14c1.572-.602 2.406-2.252 1.86-3.679-.547-1.427-2.27-2.098-3.842-1.496l.437 1.14zm2.723 8.573l.74.972c5.664-4.306 4.859-11.508 4.78-12.111-.014-.412-.189-4.005-1.988-5.632-.664-.602-1.462-.872-2.369-.803-1.073.08-1.94.513-2.577 1.286-1.453 1.765-1.127 4.689-1.113 4.813l1.213-.144c-.001-.015-.056-.527-.016-1.208l.006.003-.004.006c.11-.202.543-.822 1.549-.29 1.275.676 2.268 4.53.067 8.798-.004.007-.696 1.304-1.433 2.07l.88.846c.857-.89 1.603-2.291 1.635-2.35 2.508-4.862 1.332-9.43-.578-10.442-.64-.34-1.251-.435-1.818-.29.13-.376.307-.737.557-1.039.422-.511.986-.787 1.724-.843.567-.042 1.044.118 1.457.49 1.229 1.11 1.563 3.837 1.588 4.794v.035l.006.034c.01.07.905 7.044-4.306 11.005zm-4.51-2.626c.703-.29 1.185-.894 1.29-1.613.12-.826-.253-1.66-1.024-2.287-.187-.151-.22-.286-.23-.387-.031-.39.32-.878.417-.987l-.916-.807c-.082.093-.797.935-.717 1.895.04.48.274.907.675 1.234.43.349.643.772.586 1.162-.043.294-.243.535-.549.662-.904.374-1.573 1.523-1.52 2.616l1.219-.058c-.027-.56.347-1.255.768-1.43zm3.196-8.993v.001zm17.116 1.5a.61.61 0 00-.473-.152l-6.194.67c-.009-4.377-1.417-9.099-4.66-10.303-4.226-1.57-7.103 1.11-7.347 1.348-3.885 3.264-2.669 8.033-2.615 8.235l1.18-.315c-.048-.184-1.06-4.252 2.243-7.006l.022-.017.02-.02c.098-.097 2.498-2.408 6.071-1.08 2.735 1.015 3.903 5.503 3.864 9.403-1.011.416-1.718 1.34-1.718 2.414 0 .81.403 1.536 1.035 2.022-.841 3.013-2.453 5.406-5.193 7.712-1.704 1.435-3.4 1.894-4.905 1.328-1.254-.472-1.915-1.467-1.954-1.719l.001.02h-1.22c0 .763.961 2.029 2.393 2.695 1.16.54 3.542 1.077 6.471-1.389 2.84-2.39 4.606-4.982 5.527-8.114.226.056.462.09.706.095l6.273.68a.609.609 0 00.676-.607v-5.445a.611.611 0 00-.203-.455zM25.18 17.63l-5.604-.608a.633.633 0 00-.066-.003c-.936 0-1.697-.642-1.697-1.431 0-.79.76-1.431 1.697-1.431a.633.633 0 00.066-.004l5.604-.607v4.084z"
                ></path>
            </g>
        </svg>
    );
}

export default EarplugLawyers;
