import React from "react";

function CommercialCleaners() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19721"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g
                fill="#000"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_0_19721)"
            >
                <path d="M9.441 8.157v2.657c0 .286.174.694.517 1.14.642.836 1.881 1.91 3.026 2.672a.443.443 0 10.491-.736c-.915-.61-1.898-1.43-2.538-2.144a4.344 4.344 0 01-.477-.62c-.074-.123-.133-.225-.133-.312V8.157a.443.443 0 00-.886 0zM10.77 26.314H5.75c-.663 0-1.18-.605-1.18-1.328v-6.2c0-3.57 1.463-5.264 2.337-6.398.466-.604.762-1.098.762-1.574V8.157a.443.443 0 00-.886 0v2.657c0 .19-.106.371-.248.584-.194.291-.461.613-.753.998-.932 1.232-2.098 3.062-2.098 6.39v6.2c0 1.232.937 2.214 2.066 2.214h5.02a.443.443 0 000-.886z"></path>
                <path d="M4.572 24.543h6.2a.443.443 0 000-.886h-6.2a.443.443 0 000 .886zM14.443 9.536l-1.764-2.732a.443.443 0 01.745-.48l1.771 2.744.012.018a1.33 1.33 0 01-2.295 1.341L11.145 7.69a.443.443 0 11.745-.48l1.771 2.744.011.019a.443.443 0 00.77-.436z"></path>
                <path d="M11.838 6.828L10.197 8.47a.442.442 0 01-.313.13H5.012A1.33 1.33 0 013.684 7.27V5.057c0-.692.333-1.495.851-2.026.38-.389.856-.631 1.363-.631h9.743a1.33 1.33 0 011.328 1.328V5.5a1.33 1.33 0 01-1.328 1.328h-3.803zM9.7 7.714l1.642-1.642a.442.442 0 01.313-.13h3.986a.443.443 0 00.443-.442V3.728a.443.443 0 00-.443-.442H5.898c-.275 0-.523.153-.73.364-.36.37-.598.926-.598 1.407v2.214c0 .245.198.443.442.443h4.689z"></path>
                <path d="M13.429 4.171V2.843a.443.443 0 01.885 0V4.17a.886.886 0 01-.885.886H9.886A.886.886 0 019 4.171V2.843a.443.443 0 01.886 0V4.17h3.543zM11.657 16.571H6.786A1.33 1.33 0 005.457 17.9v2.657a1.33 1.33 0 001.329 1.329h3.543a.443.443 0 000-.886H6.785a.443.443 0 01-.443-.443V17.9c0-.244.198-.443.443-.443h4.871a.443.443 0 000-.886zM19.53 5.31l.576-1.316a.443.443 0 01.811 0l.575 1.315 1.04.71a.443.443 0 010 .732l-1.04.711-.575 1.315a.443.443 0 01-.811 0l-.576-1.315-1.04-.71a.443.443 0 010-.732l1.04-.71zm.981-.034l-.22.504a.442.442 0 01-.156.188l-.61.418.61.417a.442.442 0 01.156.188l.22.504.221-.504a.442.442 0 01.156-.188l.61-.417-.61-.418a.442.442 0 01-.156-.188l-.22-.504zM18.373 12.395l.576-1.315a.443.443 0 01.812 0l.575 1.315 1.04.71a.443.443 0 010 .732l-1.04.711-.575 1.315a.443.443 0 01-.812 0l-.576-1.315-1.04-.71a.444.444 0 010-.732l1.04-.71zm.982-.033l-.22.504a.449.449 0 01-.157.188l-.61.418.61.417a.448.448 0 01.156.188l.22.504.222-.504a.444.444 0 01.155-.188l.61-.417-.61-.418a.444.444 0 01-.155-.188l-.221-.504zM23.137 9.349l.532-.964a.443.443 0 01.775 0l.533.964.719.706a.443.443 0 010 .632l-.719.706-.533.964a.443.443 0 01-.775 0l-.532-.964-.72-.706a.444.444 0 010-.632l.72-.706zm.92.166l-.177.319a.424.424 0 01-.077.101l-.443.436.443.436c.031.03.057.064.077.101l.177.32.176-.32a.422.422 0 01.077-.101l.444-.436-.444-.436a.424.424 0 01-.077-.101l-.176-.319zM25.385 19.671h-12.4c-.758 0-1.329.546-1.329 1.152v1.24c0 .606.571 1.151 1.329 1.151h12.4c.758 0 1.328-.545 1.328-1.151v-1.24c0-.607-.57-1.152-1.328-1.152zm0 .886c.136 0 .26.04.35.11.05.042.093.093.093.156v1.24c0 .063-.042.114-.094.155a.559.559 0 01-.35.11h-12.4a.558.558 0 01-.348-.11c-.052-.041-.094-.092-.094-.155v-1.24c0-.063.042-.114.094-.155a.559.559 0 01.349-.11h12.4z"></path>
                <path d="M24.178 13.68c1.236-.323 2.064.68 2.122 1.686.04.701-.287 1.38-1.046 1.626a.48.48 0 01-.04.011L17.24 18.77c-.272.097-.526.329-.526.725v.62a.443.443 0 01-.886 0v-.62c0-.866.554-1.373 1.152-1.572a.36.36 0 01.044-.012l7.973-1.766c.322-.113.436-.423.419-.727-.029-.502-.41-1.046-1.028-.876a.444.444 0 01-.043.01l-2.429.414a.443.443 0 01-.149-.873l2.41-.412zM17.331 14.807l-2.354.443c-1.066.175-1.993 1.174-1.993 2.65v2.214a.443.443 0 00.886 0V17.9c0-.968.555-1.662 1.26-1.777l2.365-.445a.443.443 0 00-.164-.87zM24.941 25.92l-.793-3.037a.443.443 0 01.858-.223l.808 3.1a.45.45 0 01.015.111c0 .754-.553 1.329-1.181 1.329H13.724c-.628 0-1.181-.575-1.181-1.329a.45.45 0 01.014-.111l.809-3.1a.444.444 0 01.857.223l-.793 3.038c.015.207.12.393.294.393h10.924c.174 0 .279-.186.293-.393z"></path>
                <path d="M14.754 24.985v1.772a.443.443 0 00.886 0v-1.772a.443.443 0 00-.886 0zM17.414 24.985v1.772a.443.443 0 00.886 0v-1.772a.443.443 0 00-.886 0zM20.07 24.985v1.772a.443.443 0 00.886 0v-1.772a.443.443 0 00-.886 0zM22.727 24.985v1.772a.443.443 0 00.885 0v-1.772a.443.443 0 00-.885 0z"></path>
            </g>
        </svg>
    );
}

export default CommercialCleaners;
