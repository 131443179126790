import React from "react";

function TeethAligners() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19751"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19751)">
                <path d="M28.46 8.52c-.334-2.997-2.738-5.474-5.718-5.891a6.79 6.79 0 00-3.435.4 9.017 9.017 0 01-6.655 0 6.787 6.787 0 00-3.436-.4c-2.98.417-5.384 2.894-5.717 5.89a6.763 6.763 0 002.052 5.645c.013 3.702 1.157 11.104 3.52 13.492.521.527 1.086.795 1.678.795 1.735 0 2.078-2.2 2.44-4.528.473-3.033.95-5.18 2.79-5.18s2.317 2.147 2.79 5.179c.362 2.329.705 4.528 2.44 4.528.593 0 1.157-.267 1.679-.794 2.362-2.388 3.507-9.79 3.519-13.492a6.762 6.762 0 002.053-5.645zm-2.936 5.013a.529.529 0 00-.18.404c.042 3.83-1.219 10.958-3.21 12.972-.315.318-.626.48-.924.48-.826 0-1.114-1.846-1.392-3.63-.422-2.708-.948-6.078-3.839-6.078-2.89 0-3.416 3.37-3.838 6.078-.278 1.785-.566 3.63-1.392 3.63-.298 0-.609-.161-.923-.48-1.992-2.013-3.253-9.141-3.212-12.972a.532.532 0 00-.18-.403 5.622 5.622 0 01-1.88-4.897c.28-2.521 2.304-4.606 4.81-4.956a5.737 5.737 0 012.897.336 10.07 10.07 0 007.437 0 5.74 5.74 0 012.897-.336c2.507.35 4.53 2.435 4.81 4.956a5.622 5.622 0 01-1.88 4.896z"></path>
                <path d="M24.132 9.502h-4.035V7.755a.53.53 0 00-.53-.53h-2.23a.53.53 0 00-.531.53v.147h-1.652v-.147a.53.53 0 00-.53-.53h-2.23a.53.53 0 00-.53.53v1.747H7.827a.53.53 0 000 1.062h4.035v1.747c0 .293.238.53.53.53h2.23a.53.53 0 00.53-.53v-.148h1.653v.148c0 .293.237.53.53.53h2.23a.53.53 0 00.53-.53v-1.747h4.036a.53.53 0 000-1.062zm-5.097 2.278h-1.168v-.147a.53.53 0 00-.53-.53h-2.183v-1.07a.53.53 0 00-1.061 0v1.747h-1.168V8.286h1.168v.147c0 .293.238.53.53.53h2.183v1.215a.53.53 0 001.061 0V8.286h1.168v3.494z"></path>
            </g>
        </svg>
    );
}

export default TeethAligners;
