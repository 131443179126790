import React from "react";

function HearingAid() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19509"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19509)">
                <path
                    fill="#000"
                    d="M15.75 1.5C10.438 1.5 6 5.068 6 10.35c0 2.946 1.424 4.762 3.006 6.589 1.578 1.82 3.348 3.735 4.5 7.172.002.01.007.02.01.03.73 2.517 3.086 4.359 5.87 4.359 3.364 0 6.114-2.698 6.114-6.007h-1.3c0 2.609-2.147 4.721-4.814 4.721-2.216 0-4.067-1.462-4.631-3.445v-.02l-.01-.01c-1.233-3.7-3.192-5.832-4.754-7.634C8.43 14.302 7.3 12.903 7.3 10.35c0-4.581 3.71-7.564 8.45-7.564 4.67 0 8.45 3.776 8.45 8.357h1.3c0-5.281-4.37-9.643-9.75-9.643zm0 3.857c-3.223 0-5.85 2.598-5.85 5.786h1.3c0-2.493 2.03-4.5 4.55-4.5s4.55 2.007 4.55 4.5a4.45 4.45 0 01-1.605 3.415l-.7-1.095A3.193 3.193 0 0019 11.143c0-1.768-1.463-3.215-3.25-3.215s-3.25 1.447-3.25 3.215c0 1.767 1.463 3.214 3.25 3.214.405 0 .79-.076 1.148-.211l3.829 6.017 1.096-.683-2.437-3.828c1.338-1.06 2.214-2.685 2.214-4.51 0-3.187-2.627-5.785-5.85-5.785zm0 3.857a1.93 1.93 0 011.95 1.929 1.93 1.93 0 01-1.95 1.928 1.93 1.93 0 01-1.95-1.928 1.93 1.93 0 011.95-1.929z"
                ></path>
            </g>
        </svg>
    );
}

export default HearingAid;
