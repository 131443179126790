import React from "react";

function BusinessPhoneSystems() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19490"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19490)">
                <path d="M8.56 10.596a.468.468 0 00.468-.468.468.468 0 00-.037-.178.47.47 0 00-.098-.154.468.468 0 00-.51-.098.537.537 0 00-.155.098.468.468 0 000 .664.541.541 0 00.155.099.468.468 0 00.177.037z"></path>
                <path d="M20.925 1.238h-9.752a3.083 3.083 0 00-3.08 3.079v4.408a.468.468 0 00.937 0V4.317a2.148 2.148 0 012.143-2.143h9.751a2.149 2.149 0 012.144 2.143v19.382H9.03V11.533a.468.468 0 00-.936 0v13.766a3.083 3.083 0 003.079 3.08h9.751a3.083 3.083 0 003.08-3.08V4.317a3.083 3.083 0 00-3.08-3.079zm0 26.204h-9.752a2.149 2.149 0 01-2.143-2.143v-.665h14.038v.665a2.148 2.148 0 01-2.143 2.143z"></path>
                <path d="M15.579 4.045a.468.468 0 000-.936h-1.404a.468.468 0 000 .936h1.404zM17.451 3.577a.468.468 0 10-.935 0 .468.468 0 00.936 0zM16.985 26.038a.936.936 0 11-1.872 0 .936.936 0 011.872 0zM11.367 13.872a4.679 4.679 0 109.358 0 4.679 4.679 0 00-9.358 0zm4.68-3.744a3.743 3.743 0 110 7.485 3.743 3.743 0 010-7.485z"></path>
                <path d="M13.51 14.658l1.914 1.147h.089c.05.018.101.027.154.028.053 0 .105-.01.155-.028h.037a.474.474 0 00.136-.09l2.681-2.68a.468.468 0 00-.66-.66l-2.438 2.433-1.586-.936a.468.468 0 10-.468.8l-.014-.014z"></path>
            </g>
        </svg>
    );
}

export default BusinessPhoneSystems;
