import React from "react";

function PasswordManagers() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19604"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19604)">
                <path d="M27.844 16.58a2.214 2.214 0 00-.915-.226h-3.995v-2.979c0-.784-.638-1.422-1.422-1.422H20V7.47c0-1.162-.506-2.252-1.425-3.07-.905-.804-2.106-1.248-3.381-1.248s-2.476.444-3.382 1.249c-.919.817-1.425 1.907-1.425 3.07v4.482H8.875c-.784 0-1.422.638-1.422 1.422v2.979H3.46c-.047 0-.475.006-.915.226-.618.309-.958.894-.958 1.647v6.356c0 .754.34 1.338.958 1.647.44.22.868.226.915.226h23.47c.047 0 .475-.006.914-.226.618-.309.959-.893.959-1.647v-6.356c0-.753-.34-1.338-.958-1.647zM11.2 7.47c0-1.932 1.792-3.505 3.994-3.505s3.994 1.573 3.994 3.505v4.484H11.2V7.47zm-2.934 5.905a.61.61 0 01.61-.609h12.637a.61.61 0 01.609.61v2.978h-4.601a2.366 2.366 0 00-2.327-1.956 2.366 2.366 0 00-2.327 1.956H8.266v-2.979zm8.423 2.98h-2.99a1.552 1.552 0 011.495-1.144c.714 0 1.316.485 1.495 1.143zm11.3 8.229c0 .439-.16.737-.489.91-.27.142-.558.15-.573.15H3.459a1.44 1.44 0 01-.552-.14c-.342-.172-.509-.473-.509-.92v-6.357c0-.44.16-.737.489-.91.27-.142.558-.15.574-.15h23.468c.002 0 .282.005.551.14.343.171.509.472.509.92v6.357z"></path>
                <path d="M8.321 20.06a.406.406 0 00-.562-.117l-1.083.71v-1.204a.406.406 0 00-.812 0v1.204l-1.083-.71a.406.406 0 10-.445.68l1.193.782-1.193.782a.406.406 0 00.445.68l1.083-.71v1.204a.406.406 0 00.812 0v-1.204l1.083.71a.404.404 0 00.562-.117.406.406 0 00-.117-.563l-1.193-.782 1.193-.782a.406.406 0 00.117-.563zM14.434 20.06a.406.406 0 00-.562-.117l-1.082.71v-1.204a.406.406 0 00-.813 0v1.204l-1.082-.71a.406.406 0 10-.446.68l1.193.782-1.193.782a.406.406 0 00.446.68l1.082-.71v1.204a.406.406 0 00.813 0v-1.204l1.082.71a.405.405 0 00.562-.117.406.406 0 00-.117-.563l-1.193-.782 1.193-.782a.406.406 0 00.117-.563zM20.302 20.06a.406.406 0 00-.563-.117l-1.082.71v-1.204a.406.406 0 00-.813 0v1.204l-1.082-.71a.406.406 0 10-.445.68l1.193.782-1.194.782a.406.406 0 00.446.68l1.082-.71v1.204a.406.406 0 00.813 0v-1.204l1.082.71a.404.404 0 00.563-.117.406.406 0 00-.118-.563l-1.193-.782 1.193-.782a.406.406 0 00.118-.563zM26.169 20.06a.406.406 0 00-.563-.117l-1.082.71v-1.204a.406.406 0 00-.813 0v1.204l-1.082-.71a.406.406 0 10-.445.68l1.193.782-1.193.782a.406.406 0 00.445.68l1.082-.71v1.204a.406.406 0 00.813 0v-1.204l1.082.71a.404.404 0 00.563-.117.406.406 0 00-.117-.563l-1.193-.782 1.193-.782a.407.407 0 00.117-.563z"></path>
            </g>
        </svg>
    );
}

export default PasswordManagers;
