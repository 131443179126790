import React from "react";

function MealKits() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19643"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19643)">
                <path d="M29.325 24H1.873c-.372 0-.674.269-.674.6 0 .331.302.6.674.6h27.452c.372 0 .674-.269.674-.6 0-.331-.302-.6-.674-.6zM3.07 22.8h23.843l.007-.001h.007c.37 0 .671-.297.671-.665 0-6.088-4.407-11.172-10.22-12.279a3.052 3.052 0 00.724-1.975c0-1.698-1.392-3.08-3.104-3.08-1.711 0-3.103 1.381-3.103 3.08 0 .75.273 1.44.725 1.975-5.814 1.107-10.222 6.19-10.222 12.279 0 .367.301.666.672.666zM13.238 7.88c0-.963.79-1.748 1.761-1.748.971 0 1.762.785 1.762 1.748 0 .964-.791 1.748-1.762 1.748a1.757 1.757 0 01-1.761-1.748zm1.76 3.08c5.982 0 10.888 4.655 11.237 10.508H3.763C4.112 15.616 9.017 10.96 15 10.96z"></path>
            </g>
        </svg>
    );
}

export default MealKits;
