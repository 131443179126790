import React from "react";

function MedicalSoftware() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19472"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19472)">
                <path d="M25.168 28.5H6.332a.328.328 0 01-.326-.286.328.328 0 01.237-.363l15.923-4.472c.177-.05.36.055.41.226a.332.332 0 01-.227.407l-10.566 2.97h11.622V6.308c0-.182.149-.33.331-.33h1.432c.183 0 .332.148.332.33V28.17c0 .181-.15.33-.332.33zm-16.437-.66h16.106V6.638h-.769v20.674c0 .181-.15.33-.332.33H9.428l-.697.198z"></path>
                <path d="M6.332 28.5a.32.32 0 01-.2-.066A.328.328 0 016 28.17V6.308c0-.149.1-.275.243-.32l15.923-4.476c.1-.028.21-.006.293.055.083.06.133.16.133.264v21.862c0 .148-.1.275-.243.319L6.426 28.483a.282.282 0 01-.094.017zm.331-21.945V27.73l15.26-4.284V2.271L6.663 6.555z"></path>
                <path d="M13.01 20.723a.32.32 0 01-.198-.066.328.328 0 01-.133-.264v-3.085l-2.57.72a.348.348 0 01-.294-.055.328.328 0 01-.133-.264v-3.02c0-.148.1-.274.244-.318l2.753-.776v-3.272c0-.149.1-.275.243-.319l2.571-.72c.1-.028.21-.006.293.055.083.06.133.159.133.264v3.09l2.57-.726c.1-.027.21-.005.294.056.083.06.133.159.133.264v3.024c0 .149-.1.275-.244.32l-2.753.775v3.278c0 .148-.1.275-.243.319l-2.571.72h-.094zm0-4.18a.32.32 0 01.2.066c.083.06.132.16.132.264v3.086l1.908-.534v-3.278c0-.148.1-.275.243-.319l2.754-.775v-2.327l-2.571.726a.348.348 0 01-.293-.055.328.328 0 01-.133-.264v-3.09l-1.908.533v3.272c0 .149-.099.275-.243.32l-2.753.775v2.332l2.57-.72a.62.62 0 01.095-.012z"></path>
                <path d="M23.737 27.642H9.384a.328.328 0 01-.326-.286.328.328 0 01.237-.363l12.628-3.547V3.8c0-.182.15-.33.332-.33h1.476c.182 0 .332.148.332.33v23.512a.324.324 0 01-.327.33zm-11.96-.66H23.4V4.13h-.813V23.7c0 .148-.1.275-.244.319l-10.565 2.964z"></path>
            </g>
        </svg>
    );
}

export default MedicalSoftware;
