import React from "react";

function DigitalCopiers() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_1832_3825"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_1832_3825)">
                <path d="M4.5 7.5H7V2a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v5.5h2.5A2.503 2.503 0 0129 10v9a2.503 2.503 0 01-2.5 2.5H24V28a.5.5 0 01-.5.5h-16A.5.5 0 017 28v-6.5H4.5A2.504 2.504 0 012 19v-9a2.503 2.503 0 012.5-2.5zm18.5-5H8v5h15v-5zm-15 25h15v-11H8v11zM3 19a1.503 1.503 0 001.5 1.5H7v-4h-.5a.5.5 0 010-1h18a.5.5 0 010 1H24v4h2.5A1.503 1.503 0 0028 19v-9a1.502 1.502 0 00-1.5-1.5h-22A1.503 1.503 0 003 10v9z"></path>
                <path d="M19.5 20.5h-8a.5.5 0 010-1h8a.5.5 0 010 1zM19.5 24.5h-8a.5.5 0 010-1h8a.5.5 0 110 1zM6.502 11.5h2a.5.5 0 110 1h-2a.5.5 0 010-1z"></path>
            </g>
        </svg>
    );
}

export default DigitalCopiers;
