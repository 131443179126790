import React from "react";

const SoftwareIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="none"
            viewBox="0 0 35 35"
        >
            <mask
                id="mask0_1408_4339"
                style={{ maskType: "alpha" }}
                width="35"
                height="35"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#1693F0" d="M0 0H35V35H0z"></path>
            </mask>
            <g
                fill="#1693F0"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_1408_4339)"
            >
                <path d="M23.136 2.906A15.951 15.951 0 1012.88 33.115a15.951 15.951 0 0010.256-30.21zM7.007 20.96c.394 1.47 2.627.871 2.233-.597v-.002a9.078 9.078 0 016.418-11.117c1.469-.393.87-2.626-.598-2.233a11.393 11.393 0 00-8.053 13.95zm9.707-7.772a4.995 4.995 0 112.587 9.65 4.995 4.995 0 01-2.587-9.65z"></path>
                <path d="M17.731 16.517a1.592 1.592 0 10.004 0h-.004z"></path>
            </g>
        </svg>
    );
};

export default SoftwareIcon;
