import React from "react";

function VPN() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19566"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19566)">
                <path d="M15.152 15.902a.553.553 0 00.27 0c2.575-.648 4.372-2.8 4.372-5.244V7.47a.565.565 0 00-.564-.563c-1.419 0-2.703-.547-3.514-1.493a.586.586 0 00-.856 0c-.812.946-2.096 1.492-3.515 1.492a.565.565 0 00-.564.564v3.188c0 2.445 1.797 4.596 4.371 5.244zm-3.244-7.891c1.3-.113 2.49-.614 3.38-1.42.89.811 2.078 1.307 3.379 1.42v2.653c0 1.91-1.352 3.548-3.38 4.112-2.028-.564-3.38-2.197-3.38-4.112V8.01z"></path>
                <path d="M26.627 2.4H3.955c-1.205 0-2.185.98-2.185 2.186v17.039c0 1.2.98 2.18 2.185 2.18h7.39v2.253H9.092a.565.565 0 00-.563.563c0 .31.253.563.563.563h12.393c.31 0 .563-.253.563-.563a.565.565 0 00-.563-.563h-2.253v-2.253h7.396c1.205 0 2.186-.98 2.186-2.186V4.585A2.195 2.195 0 0026.627 2.4h-.001zM3.955 3.527h22.673c.58 0 1.059.473 1.059 1.059v13.586H2.896V4.586c0-.586.472-1.06 1.058-1.06zm14.15 22.531h-5.633v-2.253h5.633v2.253zm8.522-3.38H3.955a1.058 1.058 0 01-1.059-1.058v-2.321h24.785v2.32c0 .587-.474 1.06-1.054 1.06z"></path>
            </g>
        </svg>
    );
}

export default VPN;
