import React from "react";

function StorageContainer() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19697"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19697)">
                <path d="M28.194 11.024H15.666v-6.96a.464.464 0 00-.464-.464H2.21a.464.464 0 00-.464.464v22.272a.464.464 0 00.464.464h25.984a.464.464 0 00.464-.464V11.488a.464.464 0 00-.464-.464zm-.464 7.424H15.666v-6.496H27.73v6.496zM2.674 11.952h12.064v6.496H2.674v-6.496zm0-7.424h12.064v6.496H2.674V4.528zm0 14.848h12.064v6.496H2.674v-6.496zm25.056 6.496H15.666v-6.496H27.73v6.496z"></path>
                <path d="M10.097 19.84a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM12.882 19.84a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM4.53 19.84a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM7.316 19.84a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM23.089 25.408a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM25.874 25.408a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM17.523 25.408a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM20.308 25.408a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM23.089 17.984a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM25.874 17.984a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM17.523 17.984a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM20.308 17.984a.464.464 0 00.464-.464v-4.64a.464.464 0 00-.928 0v4.64a.464.464 0 00.464.464zM10.097 12.416a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM12.882 12.416a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM4.53 12.416a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM7.316 12.416a.464.464 0 00-.464.464v4.64a.464.464 0 00.928 0v-4.64a.464.464 0 00-.464-.464zM10.097 4.992a.464.464 0 00-.464.464v4.64a.464.464 0 10.928 0v-4.64a.464.464 0 00-.464-.464zM12.882 4.992a.464.464 0 00-.464.464v4.64a.464.464 0 10.928 0v-4.64a.464.464 0 00-.464-.464zM4.53 4.992a.464.464 0 00-.464.464v4.64a.464.464 0 10.928 0v-4.64a.464.464 0 00-.464-.464zM7.316 4.992a.464.464 0 00-.464.464v4.64a.464.464 0 10.928 0v-4.64a.464.464 0 00-.464-.464z"></path>
            </g>
        </svg>
    );
}

export default StorageContainer;
