import React from "react";

function HostingServices() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19562"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19562)">
                <path
                    fill="#000"
                    d="M25.437 4.8H4.96a.64.64 0 00-.64.64v5.119c0 .353.287.64.64.64h20.477a.64.64 0 00.64-.64v-5.12a.64.64 0 00-.64-.64zm-.64 5.119H5.6v-3.84h19.197v3.84zM6.56 7.999a.96.96 0 111.92 0 .96.96 0 01-1.92 0zm17.277.32H12.319v-.64h11.518v.64zm1.6 3.52H4.96a.64.64 0 00-.64.64v5.119c0 .353.287.64.64.64h9.279v2.643a2.564 2.564 0 00-1.836 1.835H4.32v1.28h8.083a2.562 2.562 0 002.476 1.92c1.19 0 2.19-.818 2.475-1.92h8.723v-1.28h-8.724a2.565 2.565 0 00-1.835-1.835v-2.644h9.919a.64.64 0 00.64-.64v-5.119a.64.64 0 00-.64-.64zm-9.279 11.518a1.28 1.28 0 11-2.56 0 1.28 1.28 0 012.56 0zm8.639-6.4H5.6v-3.839h19.197v3.84zM6.56 15.038a.96.96 0 111.92 0 .96.96 0 01-1.92 0zm17.277.32H12.319v-.639h11.518v.64z"
                ></path>
            </g>
        </svg>
    );
}

export default HostingServices;
