import React from "react";

function OnlineDating() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19764"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19764)">
                <path
                    fill="#000"
                    d="M15.747 11.217a2.542 2.542 0 011.813-.664 2.56 2.56 0 011.757.812c.465.493.733 1.154.748 1.847.111 3.467-2.856 5.297-4.314 5.297-1.476 0-4.43-1.838-4.32-5.292h-.001c.014-.694.28-1.355.745-1.85a2.56 2.56 0 011.758-.815 2.54 2.54 0 011.814.665zm-2.917 2.045c-.08 2.466 2.067 3.785 2.92 3.785a3.566 3.566 0 002.127-1.337 3.86 3.86 0 00.787-2.458 1.28 1.28 0 00-.466-.966 1.168 1.168 0 00-1.01-.234c-.354.085-.652.33-.813.67a.699.699 0 01-.627.404.699.699 0 01-.627-.405 1.218 1.218 0 00-.817-.672 1.17 1.17 0 00-1.014.24 1.283 1.283 0 00-.46.973zM2.2 21.268h23.59V8.158c0-.582-.221-1.14-.615-1.552a2.058 2.058 0 00-1.487-.643H7.806c-.557 0-1.092.232-1.486.643-.394.412-.616.97-.616 1.553v7.987c0 .404-.314.732-.7.732-.388 0-.701-.328-.701-.732V8.158c0-.97.37-1.9 1.027-2.585A3.433 3.433 0 017.806 4.5h15.882c.929.001 1.82.387 2.476 1.073a3.746 3.746 0 011.027 2.585v13.11c3.068 0 2.803-.27 2.803 2.073 0 .97-.37 1.9-1.027 2.586A3.434 3.434 0 0126.491 27H5.003a3.435 3.435 0 01-2.476-1.073A3.747 3.747 0 011.5 23.342V22c0-.404.314-.732.7-.732zm.701 2.073c0 .583.222 1.14.617 1.552a2.06 2.06 0 001.485.644h21.488a2.06 2.06 0 001.485-.644c.394-.412.616-.97.617-1.552v-.61H2.9v.61zm2.368-3.42a.723.723 0 00.412-.498.756.756 0 00-.138-.642.688.688 0 00-.575-.267.695.695 0 00-.549.324.754.754 0 00.033.877.678.678 0 00.817.206z"
                ></path>
            </g>
        </svg>
    );
}

export default OnlineDating;
