import React from "react";

function AccountingSoftware() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19612"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19612)">
                <path d="M27.718 24.313H26.44V9.737c0-.9-.728-1.703-1.298-1.703h-2.841V6.08c0-.008-.004-.017-.004-.025-.004-.037-.008-.074-.02-.11-.013-.013-.013-.025-.021-.037a.44.44 0 00-.078-.115s0-.004-.004-.004l-3.267-3.271s-.004 0-.004-.004a.438.438 0 00-.115-.078l-.037-.013a.331.331 0 00-.11-.02c-.008 0-.017-.004-.025-.004h-9.67a.41.41 0 00-.41.41v5.224h-2.35c-1.454 0-2.105.856-2.105 1.703v14.576H2.808a.41.41 0 00-.41.41v1.322a2.563 2.563 0 002.563 2.559h20.603a2.563 2.563 0 002.563-2.559v-1.323a.41.41 0 00-.409-.409zM8.945 18.9h12.943a.41.41 0 00.41-.41v-8.122h1.715v12.336h-17.5V10.367h2.023v8.124c0 .225.184.41.41.41zm10.08-15.104l1.876 1.875h-1.875V3.796zm-9.67-.578h8.852V6.08c0 .226.184.41.41.41h2.861v11.587H9.355V3.218zM4.9 9.737c0-.545.492-.885 1.286-.885h2.35v.696H6.104a.41.41 0 00-.41.41v13.155c0 .225.185.41.41.41h18.318a.41.41 0 00.41-.41V9.958a.41.41 0 00-.41-.41h-2.125v-.696h2.821c.127.033.504.397.504.885v14.576H4.9V9.737zm12.095 16.487h-3.464v-1.093h3.464v1.093zm10.314-.18c0 .958-.783 1.74-1.745 1.74H4.961a1.745 1.745 0 01-1.744-1.74v-.913h9.495v1.093c0 .45.368.82.819.82h3.463c.45 0 .82-.37.82-.82v-1.093h9.494v.913z"></path>
                <path d="M14.097 7.997h-.909v-.909a.41.41 0 00-.41-.41.41.41 0 00-.409.41v.91h-.913a.41.41 0 00-.41.408c0 .226.185.41.41.41h.91v.909c0 .225.183.41.409.41a.41.41 0 00.41-.41v-.91h.908a.41.41 0 00.41-.409c0-.225-.18-.409-.406-.409zM13.998 12.058a.408.408 0 00-.578 0l-.643.643-.642-.643a.408.408 0 10-.578.578l.643.642-.643.643a.408.408 0 00.29.696.42.42 0 00.292-.119l.642-.642.643.643a.402.402 0 00.29.118.408.408 0 00.29-.696l-.641-.643.635-.642a.408.408 0 000-.578zM19.378 7.997h-2.64a.41.41 0 00-.41.41c0 .225.184.409.41.409h2.64a.41.41 0 00.41-.41.41.41 0 00-.41-.409zM19.279 12.058a.408.408 0 00-.577 0l-1.867 1.867a.408.408 0 00.29.696c.107 0 .21-.04.29-.118l1.864-1.867a.408.408 0 000-.578zM16.902 12.824a.405.405 0 00.315 0c.05-.02.095-.05.131-.086a.412.412 0 000-.581.409.409 0 10-.446.667zM18.898 13.733a.475.475 0 00-.135.086.43.43 0 00-.119.29.405.405 0 00.41.41.405.405 0 00.41-.41.43.43 0 00-.12-.29.425.425 0 00-.446-.086z"></path>
            </g>
        </svg>
    );
}

export default AccountingSoftware;
