import React from "react";

const LifeStyleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="35"
            fill="none"
            viewBox="0 0 39 35"
        >
            <path
                fill="#1693F0"
                d="M23.634 18.667l-2.219-7.212-3.236 12.624a.771.771 0 01-.712.57h-.046a.767.767 0 01-.73-.49l-4.448-11.233-2.793 5.84a.787.787 0 01-.712.436H1.98c4.882 9.067 16.495 15.37 17.115 15.7.12.062.25.098.379.098.13 0 .268-.036.388-.098.62-.33 12.224-6.624 17.106-15.691H24.393a.79.79 0 01-.759-.544z"
            ></path>
            <path
                fill="#1693F0"
                d="M27.55 0a11.43 11.43 0 00-8.067 3.34A11.408 11.408 0 0011.426 0C5.13 0 0 5.116 0 11.395c0 2.26.523 4.42 1.386 6.47h6.938l3.34-7.221a.764.764 0 01.734-.449c.312.01.587.211.698.504l4.184 10.937 3.304-13.325a.782.782 0 01.734-.595c.377-.009.66.22.762.558l2.854 9.592h12.628c.863-2.05 1.386-4.21 1.386-6.471C38.967 5.117 33.846 0 27.55 0z"
            ></path>
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
                d="M3.5 15.75C2.566 14 1.4 9.52 4.2 5.6"
            ></path>
        </svg>
    );
};

export default LifeStyleIcon;
