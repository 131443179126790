import React from "react";

function Pos() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19664"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19664)">
                <path d="M25.838 6.017h-4.441V3.95c-.001-.856-.694-1.55-1.55-1.55h-15.5c-.856 0-1.55.694-1.55 1.55v21.7c0 .856.694 1.549 1.55 1.55h15.5c.855-.001 1.549-.694 1.55-1.55V21h4.44a1.761 1.761 0 001.76-1.76V7.776a1.76 1.76 0 00-1.76-1.758zM20.364 25.65a.517.517 0 01-.517.517h-15.5a.517.517 0 01-.517-.517V3.95c0-.285.232-.517.517-.517h15.5c.285 0 .516.232.517.517v21.7zm6.2-6.41c0 .401-.325.726-.726.727h-4.441V7.05h4.441c.4 0 .725.325.726.725v11.466z"></path>
                <path d="M16.748 5.5h-9.3c-.855 0-1.549.694-1.55 1.55v2.067c.001.855.695 1.549 1.55 1.55h9.3a1.552 1.552 0 001.55-1.55V7.05c0-.856-.694-1.55-1.55-1.55zm.517 3.617a.517.517 0 01-.517.516h-9.3a.517.517 0 01-.516-.516V7.05c0-.285.231-.517.516-.517h9.3c.286 0 .517.232.517.517v2.067zM16.745 12.217a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033zM12.097 12.217a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033zM7.448 12.217a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033zM12.097 16.867a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033zM7.448 16.867a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033zM16.745 16.867c-.855 0-1.549.694-1.55 1.55v4.65a1.55 1.55 0 003.1 0v-4.65c0-.856-.694-1.55-1.55-1.55zm.517 6.2a.517.517 0 01-1.033 0v-4.65a.517.517 0 011.033 0v4.65zM12.097 21.517a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033zM7.448 21.517a1.55 1.55 0 100 3.1 1.55 1.55 0 000-3.1zm0 2.066a.517.517 0 110-1.033.517.517 0 010 1.033z"></path>
            </g>
        </svg>
    );
}

export default Pos;
