import React from "react";

const AutomotiveIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="35"
            fill="none"
            viewBox="0 0 42 35"
        >
            <path
                fill="#1693F0"
                fillRule="evenodd"
                d="M40.819 15.38c-.375-.706-1.372-1.496-1.372-1.496L35.332 3.118C34.459 1.08 31.965 0 30.884 0H10.683C9.602 0 7.066 1.081 6.235 3.118L2.12 13.884s-.998.79-1.372 1.497C.374 16.087 0 16.71 0 21.49c0 4.656.956 6.318 2.078 6.858l1.164.084v4.364c0 1.413.832 2.203 2.203 2.203H6.65c1.372 0 2.078-.79 2.078-2.203l.001-4.365h24.067v4.365c0 1.413.748 2.203 2.078 2.203h1.205c1.372 0 2.203-.79 2.203-2.203l.001-4.365 1.164-.083c1.164-.582 2.078-2.244 2.078-6.858.042-4.78-.374-5.404-.706-6.11zM8.023 23.735a2.679 2.679 0 01-2.66-2.66 2.679 2.679 0 012.66-2.66 2.679 2.679 0 012.66 2.66 2.652 2.652 0 01-2.66 2.66zm25.605 0a2.679 2.679 0 01-2.66-2.66 2.679 2.679 0 012.66-2.66 2.679 2.679 0 012.66 2.66 2.679 2.679 0 01-2.66 2.66zm-.084-11.721H8.023s-.5.042-.333-.457c.125-.416 1.829-4.739 2.203-5.903.166-.415.998-1.33 2.12-1.288h17.582c1.123-.083 1.954.873 2.12 1.288.374 1.164 2.079 5.487 2.203 5.903.125.498-.374.457-.374.457z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default AutomotiveIcon;
