import React from "react";

function CreditRepair() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19756"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19756)">
                <path d="M26.575 12.953L24.792 9.39l-1.446-.895h-1.942V6.957l-1.01-1.01H2.99l-1.01 1.01V20.86l1.01 1.01h5.359v2.174l1.01 1.01h14.22l3.783-3.821h2.64v-8.28h-3.427zM3.255 20.33V7.485l.264-.264h16.347l.265.264v1.01h-5.678l-1.01 1.009v1.802l1.647 1.647h4.308l1.05 2.362v5.015l-.264.265H3.52l-.265-.264zm25.473-.373h-1.91l-3.768 3.821H9.887l-.264-.264v-1.646h11.09l1.01-1.01v-5.811l-1.496-3.369h-4.608l-.901-.901v-.745l.264-.265h8.015l.83.513 1.96 3.945h2.941v5.732z"></path>
                <path d="M5.164 17.411h13.374v1.274H5.164V17.41zM5.164 14.864h1.91v1.273h-1.91v-1.273zM8.348 14.864h1.91v1.273h-1.91v-1.273zM11.535 14.864h1.91v1.273h-1.91v-1.273z"></path>
            </g>
        </svg>
    );
}

export default CreditRepair;
