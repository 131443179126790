import React from "react";

function ATMMachines() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19529"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19529)">
                <path d="M20.332 6.34a.46.46 0 00-.398-.23L6.66 6.115a.46.46 0 00-.41.667l7.51 14.85a.46.46 0 00.41.251h.002l14.358-.059a.46.46 0 00.396-.69L20.332 6.338zM14.45 20.961L7.406 7.032l12.263-.002 8.061 13.876-13.28.056z"></path>
                <path d="M16.166 7.774l-4.128.002a.46.46 0 00-.457.412c-.054.518-.611.962-1.49 1.188a.46.46 0 00-.295.654l4.275 8.381a.46.46 0 00.313.24c1.42.304 2.753.946 3.66 1.761a.458.458 0 00.308.118h.002l4.553-.017a.46.46 0 00.458-.458c.004-.652.535-1.178 1.458-1.445a.46.46 0 00.268-.676l-4.962-8.357a.46.46 0 00-.306-.216c-1.318-.262-2.544-.802-3.364-1.481a.463.463 0 00-.294-.106zm7.851 10.153c-.82.367-1.357.956-1.519 1.668l-3.974.015c-.968-.822-2.309-1.471-3.733-1.808l-3.93-7.709c.791-.309 1.329-.796 1.542-1.398l3.601-.002c.884.688 2.117 1.236 3.437 1.526l4.576 7.708z"></path>
                <path d="M13.67 12.511c-.277.46-.273 1.009.01 1.547.65 1.232 2.662 2.198 4.58 2.198h.014c1.193-.003 2.097-.388 2.482-1.056.275-.48.251-1.047-.067-1.6-.687-1.19-2.68-2.123-4.547-2.123-1.177.002-2.078.38-2.472 1.034zm.787.475c.17-.284.703-.588 1.694-.59 1.508 0 3.222.763 3.742 1.664.152.264.174.493.066.681-.165.287-.695.594-1.687.596l-.012.46v-.46c-1.554 0-3.279-.782-3.767-1.707-.198-.376-.076-.578-.036-.645z"></path>
                <path d="M4.662 14.816h1.41a.46.46 0 000-.92h-1.41a2.335 2.335 0 01-2.333-2.332v-4.71A2.336 2.336 0 014.662 4.52h18.17a2.336 2.336 0 012.332 2.335v4.71c0 .408-.107.809-.309 1.16a.46.46 0 10.797.458c.282-.49.431-1.05.431-1.618v-4.71A3.257 3.257 0 0022.832 3.6H4.662A3.257 3.257 0 001.41 6.854v4.71a3.255 3.255 0 003.252 3.252z"></path>
                <path d="M7.07 9.173a.46.46 0 00-.822.414l7.124 14.145a.46.46 0 00.41.253h.003l14.267-.063h.026a.46.46 0 00.309-.796l-.585-.978a.46.46 0 10-.79.471l.232.388-13.18.058L7.07 9.173z"></path>
                <path d="M7.068 12.152a.46.46 0 10-.818.42l6.753 13.182a.46.46 0 00.409.25l14.109-.014a.46.46 0 00.409-.67l-.543-1.06a.46.46 0 00-.819.42l.201.392-13.076.012-6.625-12.932z"></path>
            </g>
        </svg>
    );
}

export default ATMMachines;
