import React from "react";

const FinancialIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="none"
            viewBox="0 0 35 35"
        >
            <path
                fill="#1693F0"
                d="M17.5 4.53c-7.152 0-12.97 5.818-12.97 12.97 0 7.152 5.819 12.97 12.97 12.97 7.151 0 12.97-5.819 12.97-12.97 0-7.151-5.819-12.97-12.97-12.97zm0 11.826c2.549 0 4.62 2.104 4.62 4.694 0 2.196-1.486 4.042-3.495 4.551v.501c0 .63-.506 1.144-1.125 1.144a1.138 1.138 0 01-1.125-1.144v-.5c-2.01-.51-3.495-2.356-3.495-4.552 0-.634.507-1.143 1.126-1.143a1.13 1.13 0 011.125 1.143c0 1.327 1.064 2.407 2.37 2.407 1.305 0 2.368-1.08 2.368-2.407 0-1.326-1.063-2.407-2.369-2.407-2.549 0-4.62-2.104-4.62-4.694 0-2.19 1.486-4.042 3.495-4.55v-.502c0-.629.506-1.143 1.125-1.143s1.125.515 1.125 1.143v.501c2.01.51 3.495 2.362 3.495 4.551 0 .635-.507 1.144-1.126 1.144a1.13 1.13 0 01-1.125-1.144c0-1.326-1.064-2.407-2.37-2.407-1.305 0-2.368 1.08-2.368 2.407s1.063 2.407 2.369 2.407z"
            ></path>
            <path
                fill="#1693F0"
                d="M17.5 0C7.835 0 0 7.835 0 17.5S7.835 35 17.5 35 35 27.165 35 17.5 27.165 0 17.5 0zm0 31.928c-7.956 0-14.428-6.473-14.428-14.428 0-7.955 6.472-14.428 14.428-14.428S31.928 9.544 31.928 17.5 25.456 31.928 17.5 31.928z"
            ></path>
        </svg>
    );
};

export default FinancialIcon;
