import React from "react";

function WorkerCompensationInsurance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19516"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19516)">
                <path d="M15.162 10.777a4.791 4.791 0 004.788-4.788 4.791 4.791 0 00-4.788-4.787 4.791 4.791 0 00-4.787 4.787 4.791 4.791 0 004.787 4.788zm0-8.303a3.518 3.518 0 013.515 3.515 3.518 3.518 0 01-3.515 3.515 3.515 3.515 0 010-7.03zM19.147 12.528h-7.971c-2.677 0-4.856 1.99-4.856 4.436v14.06c0 .052.016.1.032.152.148 1.088 1.15 1.942 2.374 1.942 1.329 0 2.406-.997 2.406-2.226V20.16a.633.633 0 10-1.268 0v10.732c0 .519-.52.954-1.133.954-.615 0-1.133-.44-1.133-.954V16.961c0-1.744 1.607-3.164 3.582-3.164h3.347v9.966h-.857c-1.225 0-2.223 1.077-2.223 2.414 0 1.324.998 2.401 2.223 2.401h8.106c1.225 0 2.222-1.077 2.222-2.413V16.96c.004-2.446-2.174-4.433-4.851-4.433zm-4.624 14.777h-.858c-.514 0-.953-.519-.953-1.145 0-.614.439-1.129.953-1.129h.858v2.274zm8.206-8.159v7.03c0 .614-.439 1.129-.953 1.129h-5.98V13.797h3.35c1.975 0 3.583 1.42 3.583 3.163v2.186z"></path>
                <path d="M18.29 19.003a.633.633 0 00-.634.634v3.407a.633.633 0 101.269 0v-3.407a.63.63 0 00-.634-.634zM20.33 19.003a.633.633 0 00-.635.634v3.407a.633.633 0 101.269 0v-3.407a.63.63 0 00-.634-.634z"></path>
            </g>
        </svg>
    );
}

export default WorkerCompensationInsurance;
