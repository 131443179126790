import React from "react";

function AutoWarranty() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19796"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19796)">
                <path
                    fill="#000"
                    d="M7.006 2.233a.58.58 0 00-.506.56v20.98c0 .292.266.559.559.559h7.152a.581.581 0 00.567-.56.581.581 0 00-.567-.56H7.618V3.353h16.767v19.86h-.27a.581.581 0 00-.567.56c0 .296.271.564.567.56h.83a.581.581 0 00.558-.56V2.792a.581.581 0 00-.559-.56H7.006zM9.801 4.75a.582.582 0 00-.533.586.582.582 0 00.585.533H22.15a.581.581 0 00.567-.559.582.582 0 00-.567-.56H9.8zm0 2.798a.582.582 0 00-.533.586.582.582 0 00.585.533H22.15a.581.581 0 00.567-.56.582.582 0 00-.567-.56H9.8zm0 2.797a.582.582 0 00-.533.586.582.582 0 00.585.533H21.88a.581.581 0 00.567-.56.582.582 0 00-.567-.559H9.801zm0 2.797a.582.582 0 00-.533.586.582.582 0 00.585.533h5.31a.581.581 0 00.567-.56.581.581 0 00-.567-.559H9.801zm9.275.56a4.202 4.202 0 00-4.192 4.196 4.202 4.202 0 004.192 4.196 4.202 4.202 0 004.191-4.196 4.202 4.202 0 00-4.191-4.196zm0 1.119a3.067 3.067 0 013.074 3.077 3.067 3.067 0 01-3.074 3.077A3.067 3.067 0 0116 17.898a3.067 3.067 0 013.075-3.077zm1.633 1.39a.564.564 0 00-.376.192L18.5 18.475l-.707-.7a.58.58 0 00-.798-.003.582.582 0 00.012.8l1.118 1.118a.581.581 0 00.812-.026l2.236-2.518a.574.574 0 00.088-.626.573.573 0 00-.552-.309zm-4.489 5.874a.58.58 0 00-.498.568v3.637c.006.401.542.68.873.454l2.48-1.713 2.48 1.713c.332.226.868-.053.874-.454v-3.637a.581.581 0 00-.559-.567.581.581 0 00-.559.567v2.57l-1.921-1.337a.573.573 0 00-.629 0l-1.921 1.337v-2.57a.584.584 0 00-.62-.568z"
                ></path>
            </g>
        </svg>
    );
}

export default AutoWarranty;
