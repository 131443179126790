import React from "react";

const B2BIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="35"
            fill="none"
            viewBox="0 0 45 35"
        >
            <mask
                id="mask0_1652_2898"
                style={{ maskType: "alpha" }}
                width="45"
                height="35"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#1693F0" d="M0 0H45V35H0z"></path>
            </mask>
            <g fill="#1693F0" mask="url(#mask0_1652_2898)">
                <path d="M25.104 12.692L19.9 7.472 8.55 18.782l-4.409-4.495c-.216-.217-.65-.072-.65.218L1.322 31.109c0 .072.073.145.145.145L18.02 29.15c.29-.073.434-.435.217-.653l-4.481-4.495 11.348-11.311z"></path>
                <path
                    fillRule="evenodd"
                    d="M19.637 21.503l5.204 5.22 11.35-11.31 4.48 4.494c.218.218.651.073.651-.217L43.42 3.086c0-.072-.073-.145-.145-.145L26.721 5.044c-.29.072-.434.435-.217.652l4.481 4.495-11.348 11.312zm13.535-5.936a.603.603 0 010-.856l.308-.307a.61.61 0 01.86 0 .603.603 0 010 .856l-.308.307a.61.61 0 01-.86 0zm-8.496 7.805a.603.603 0 000 .856.61.61 0 00.86 0l6.499-6.469a.603.603 0 000-.856.61.61 0 00-.86 0l-6.499 6.47z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
};

export default B2BIcon;
