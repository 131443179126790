import React from "react";

function CMS() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19582"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19582)">
                <path
                    fill="#000"
                    d="M27.335 1.322H3.09A2.668 2.668 0 00.426 3.986v15.152a2.668 2.668 0 002.664 2.665h7.159v2.575h-2.89c-1.291 0-2.341.88-2.341 1.963v1.5c0 .256.207.463.462.463h19.466a.462.462 0 00.462-.463v-1.5c0-1.083-1.05-1.963-2.34-1.963h-2.89v-2.576h7.157A2.668 2.668 0 0030 19.138V3.986a2.668 2.668 0 00-2.665-2.664zM24.483 26.34v1.04H5.942v-1.04c0-.563.648-1.038 1.416-1.038h15.709c.767 0 1.416.476 1.416 1.038zm-5.23-1.962h-8.08v-2.576h8.08v2.576zm9.822-5.24c0 .96-.78 1.74-1.74 1.74H3.09c-.96 0-1.74-.78-1.74-1.74V3.986c0-.96.78-1.74 1.74-1.74h24.245c.96 0 1.74.78 1.74 1.74v15.152zM27.39 3.163H3.035a.769.769 0 00-.768.768v13.7c0 .255.207.462.462.462h24.966a.462.462 0 00.463-.462V3.93a.769.769 0 00-.768-.768zM11.38 13.967l-.924-1.277.72-.72 1.277.924a.463.463 0 00.507.024c.36-.215.748-.375 1.153-.479a.462.462 0 00.342-.374l.25-1.556h1.017l.25 1.556c.028.181.163.329.341.374.405.104.793.264 1.154.479a.463.463 0 00.507-.024l1.277-.924.72.72-.924 1.277a.462.462 0 00-.024.506 4.4 4.4 0 01.479 1.154.462.462 0 00.374.342l1.556.249v.95h-3.12a3.134 3.134 0 00-3.1-3.572 3.134 3.134 0 00-3.098 3.572h-3.12v-.95l1.556-.25a.462.462 0 00.375-.341c.103-.405.264-.793.478-1.154a.462.462 0 00-.023-.506zm1.626 2.76a2.21 2.21 0 012.207-2.206 2.21 2.21 0 012.162 2.647h-4.324a2.203 2.203 0 01-.045-.44zm14.228.441h-4.878v-1.344a.462.462 0 00-.39-.456l-1.654-.265a5.327 5.327 0 00-.345-.832l.983-1.359a.462.462 0 00-.048-.597l-1.277-1.277a.463.463 0 00-.598-.048l-1.358.983a5.323 5.323 0 00-.832-.345l-.265-1.655a.462.462 0 00-.456-.389H14.31a.462.462 0 00-.457.39l-.265 1.654a5.323 5.323 0 00-.832.345l-1.358-.983a.462.462 0 00-.598.048l-1.277 1.277a.463.463 0 00-.048.598l.984 1.358a5.327 5.327 0 00-.346.832l-1.655.265a.462.462 0 00-.389.456v1.344H3.192V4.088h24.041v13.08zM20.77 9.813c.87 0 1.576-.706 1.576-1.575s-.707-1.575-1.576-1.575c-.868 0-1.575.706-1.575 1.575s.707 1.575 1.575 1.575zm0-2.226a.652.652 0 110 1.303.652.652 0 010-1.303z"
                ></path>
            </g>
        </svg>
    );
}

export default CMS;
