import React from "react";

function GoldIRA() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_3176_5800"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_3176_5800)">
                <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M5.865 18.191h3.643l1.5-6.456a.483.483 0 01.469-.377v-.001h8.046c.237 0 .433.173.473.4l1.495 6.433h3.643c.237 0 .434.173.474.4l1.583 6.81a.486.486 0 01-.468.599V26H4.277a.486.486 0 01-.462-.626l1.582-6.806a.483.483 0 01.468-.377zm1.303-5.787H3.475c-.633 0-.633.976 0 .976h3.693c.634 0 .634-.976 0-.976zm7.851-8.922c0-.643.962-.643.962 0v3.747c0 .642-.962.642-.962 0V3.482zM7.342 6.719c-.448-.454.232-1.145.68-.691l2.612 2.65c.447.454-.232 1.144-.68.69l-2.612-2.65zm16.005-.691c.447-.454 1.128.236.68.69l-2.613 2.65c-.447.454-1.128-.235-.68-.689l2.613-2.651zm4.178 6.376h-3.693c-.633 0-.633.976 0 .976h3.693c.633 0 .633-.976 0-.976zm-17.03 5.787h10.01l-1.361-5.857h-7.287l-1.362 5.857zm4.024.976l.981 4.223.982-4.222-1.963-.002zm10.236 0h-7.287l-1.361 5.857h10.01l-1.362-5.858zm-11.223 0H6.245l-1.361 5.857h10.01l-1.362-5.858z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default GoldIRA;
