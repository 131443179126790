import React from "react";

function PayrollServices() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19512"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19512)">
                <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M9.373 6.976l12.775-.057v18.376h1.16V5.758H9.374v1.218zM12.131 3v1.217l12.776-.056-.001 18.375h1.161V3H12.131zM7.157 25.628l.712.712 1.343-1.342 1.347 1.346 1.354-1.332 1.331 1.332 1.355-1.332 1.331 1.332 1.366-1.343 1.34 1.34.757-.758V9.673H7.157v15.955zM6 8.517h14.55v17.545l-1.914 1.915-1.347-1.347-1.365 1.343-1.332-1.331-1.354 1.331-1.332-1.331-1.354 1.331-1.34-1.34-1.343 1.344L6 26.107V8.517zm8.194 11.211c.259-.26.395-.652.255-.996-.183-.45-.743-.582-1.179-.71-.492-.146-1.003-.276-1.425-.568-1.26-.869-1.172-2.97.287-3.607.149-.065.306-.109.466-.143l-.133-1.029h1.577l-.135 1.047c.262.06.516.154.74.296.474.3.731.779.844 1.292l-1.26.249a.987.987 0 00-.306-.465 1.078 1.078 0 00-.637-.213c-.41-.013-.945.094-1.101.506a.639.639 0 00.146.67c.157.159.537.323 1.141.494.604.172 1.051.349 1.34.532.29.183.517.433.68.75.486.944.287 2.345-.604 3.016-.28.21-.615.341-.964.411l.116.903h-1.577l.12-.93a2.314 2.314 0 01-.909-.44c-.5-.41-.76-1.02-.878-1.642l1.2-.271c.104.644.583 1.153 1.292 1.153.4 0 .701-.102.904-.305z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default PayrollServices;
