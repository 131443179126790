import React from "react";

function OfficeCubiclesAndSystems() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19688"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g
                fill="#000"
                clipPath="url(#clip0_0_19688)"
                mask="url(#mask0_0_19688)"
            >
                <path d="M12.676 27.02c-.088-.037-.18-.063-.26-.112-.33-.201-.429-.509-.38-.874a1.59 1.59 0 011.59-1.398c.28-.002.558 0 .837 0h.136c.003-.048.008-.081.008-.116 0-.972-.002-1.944.003-2.916 0-.111-.027-.148-.144-.146-.753.005-1.505 0-2.258.004a.578.578 0 01-.403-.144c-.581-.496-1.166-.988-1.755-1.476a.571.571 0 01-.218-.406c-.056-.555-.12-1.108-.183-1.662-.02-.173.028-.317.175-.415a.374.374 0 01.428-.004.376.376 0 01.182.293c.055.483.113.965.16 1.448a.382.382 0 00.152.29c.227.18.445.371.697.583l-.067-.618a2210.02 2210.02 0 00-.627-5.54c-.035-.309-.075-.617-.102-.926-.085-.96.624-1.742 1.586-1.743 2.37-.004 4.74-.004 7.11 0 .966 0 1.675.754 1.603 1.715-.072.973-.167 1.944-.251 2.915l-.254 2.903c-.06.69-.12 1.38-.186 2.07a.772.772 0 01-.787.713c-.776.003-1.553.003-2.33 0-.115 0-.157.021-.156.15.006.959.003 1.919.003 2.879v.143c.052.003.093.007.135.008.354.005.71-.012 1.062.02.794.072 1.426.824 1.382 1.619a.789.789 0 01-.528.7l-.123.045-6.237-.002zm6.792-6.354l.05-.608a5797.132 5797.132 0 01.486-5.505c.052-.595.11-1.19.147-1.785a.838.838 0 00-.092-.421c-.163-.311-.447-.413-.789-.413-2.29.004-4.579.005-6.868.003-.076 0-.152 0-.227.002-.316.015-.613.224-.696.529-.041.155-.038.33-.02.493.092.886.194 1.772.294 2.657l.302 2.669c.085.747.173 1.494.25 2.242.013.125.063.14.17.14 2.282-.003 4.564-.003 6.845-.002h.148zm-6.634 5.552h5.939c-.006-.054-.007-.098-.016-.14-.084-.409-.396-.645-.852-.645-1.41 0-2.82 0-4.23.002-.123 0-.245.02-.36.06-.302.108-.522.451-.48.723zm2.573-4.751v3.16h.777v-3.16h-.777z"></path>
                <path d="M2.398 14.928c.03-.09.054-.182.09-.27a1.176 1.176 0 011.103-.737H6.558v-.787c-.037-.002-.075-.006-.113-.006-.418-.002-.837 0-1.255-.003-.685-.006-1.202-.52-1.204-1.205a764.195 764.195 0 010-3.92c.002-.717.515-1.225 1.23-1.225h5.88c.716 0 1.225.51 1.225 1.227V10.323l-.792.107v-.12-2.27c0-.325-.15-.473-.476-.473H5.246c-.313 0-.463.148-.464.46v3.823c0 .34.14.478.478.478H9.855v.783c-.12-.003-.12.075-.117.165.005.21.002.42.002.635l.178.016.056.785H3.635c-.229 0-.38.108-.427.305a.39.39 0 00.348.484c.052.004.104.002.155.002h6.315l.056.79H4.79v5.963h9.015v.787c-.046.002-.088.006-.13.006H4.822c-.502 0-.837-.337-.837-.842a5733.83 5733.83 0 010-5.747c0-.161 0-.159-.16-.162-.15-.003-.306.008-.452-.022-.49-.099-.8-.4-.939-.88-.01-.037-.024-.074-.035-.112v-.356zm4.97-1.017h1.57v-.775h-1.57v.775z"></path>
                <path d="M27.802 11.654c-.048.103-.085.216-.15.31a.759.759 0 01-.655.335v-6.34H16.293v4.377h-.796v-.143V4.84c0-.149-.039-.276-.16-.367a.369.369 0 00-.395-.047.385.385 0 00-.235.322 1.254 1.254 0 00-.003.155v5.432h-.791v-4.38H3.203v6.331c-.292.044-.637-.184-.753-.502l-.052-.132V5.797a.14.14 0 00.02-.03c.142-.431.396-.615.85-.615h10.644c0-.144-.004-.273 0-.403.02-.45.23-.788.622-1.006.112-.063.241-.096.362-.143h.406a.197.197 0 00.042.02c.607.161.944.597.943 1.225v.307H26.98c.309 0 .546.126.708.386.05.08.077.173.114.26v5.856z"></path>
                <path d="M27.802 15.286c-.019.065-.037.13-.058.193a1.187 1.187 0 01-1.117.82c-.104.003-.208.005-.311 0-.08-.005-.109.027-.101.103.002.028 0 .056 0 .084v5.711c0 .529-.33.854-.86.854h-7.562v-.79h7.619v-5.958H21.51l.053-.798h4.995c.234 0 .384-.103.434-.295a.395.395 0 00-.35-.495c-.04-.003-.08-.002-.12-.002H21.62l.051-.787h1.16v-.798h-1.093v-.799H24.936c.343 0 .484-.14.484-.486V8.02c0-.301-.153-.452-.458-.452H19.13c-.3 0-.456.155-.457.457v2.311h-.786c-.002-.039-.005-.08-.005-.123 0-.744-.002-1.49 0-2.234a1.177 1.177 0 011.201-1.203h5.927c.686 0 1.206.526 1.206 1.215v3.883c0 .74-.5 1.246-1.243 1.252-.398.003-.797 0-1.195.002h-.136v.794h2.908c.638 0 1.078.345 1.231.966a.206.206 0 00.02.041v.358z"></path>
                <path d="M13.673 19.082h4.177c.255 0 .424.163.422.4a.389.389 0 01-.36.39c-.037.003-.073.002-.108.002h-4.421c-.305 0-.442-.13-.474-.431-.097-.93-.2-1.86-.3-2.79-.083-.775-.166-1.551-.247-2.327-.012-.115-.026-.23-.039-.344a.403.403 0 01.506-.446.39.39 0 01.28.339c.073.636.14 1.273.209 1.91l.3 2.802.055.495z"></path>
            </g>
            <defs>
                <clipPath id="clip0_0_19688">
                    <path
                        fill="#fff"
                        d="M0 0H25.403V23.42H0z"
                        transform="translate(2.398 3.6)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default OfficeCubiclesAndSystems;
