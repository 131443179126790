import React from "react";

function EnterpriseContent() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19571"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19571)">
                <path d="M18.912 21.55l.862.186a.445.445 0 00.48-.212l.851-1.474a.445.445 0 00-.055-.521l-.592-.655.086-.486.78-.413a.444.444 0 00.23-.47l-.296-1.677a.445.445 0 00-.378-.364l-.873-.12-.247-.428.332-.817a.445.445 0 00-.127-.509l-1.304-1.095a.444.444 0 00-.523-.036l-.747.47-.464-.17-.27-.84a.445.445 0 00-.424-.308H14.53a.445.445 0 00-.424.309l-.271.84-.464.168-.747-.469a.446.446 0 00-.523.036l-1.304 1.095a.445.445 0 00-.127.509l.333.817-.247.427-.874.121a.445.445 0 00-.377.364l-.296 1.676c-.034.191.06.38.23.471l.78.413.086.486-.592.655a.445.445 0 00-.056.52l.852 1.475a.443.443 0 00.479.212l.862-.185.379.317-.033.882a.445.445 0 00.293.435l1.6.582a.443.443 0 00.504-.145l.54-.697h.495l.54.697a.445.445 0 00.505.145l1.6-.582a.444.444 0 00.292-.435l-.032-.882.378-.317zm-1.117-.224a.444.444 0 00-.16.358l.03.775-.991.36-.476-.612a.445.445 0 00-.351-.172h-.93a.445.445 0 00-.352.172l-.476.612-.99-.36.029-.775a.445.445 0 00-.16-.358l-.711-.597a.447.447 0 00-.38-.094l-.758.163-.527-.913.52-.575a.446.446 0 00.109-.377l-.162-.915a.444.444 0 00-.23-.316l-.685-.363.183-1.037.768-.107a.444.444 0 00.325-.218l.465-.804a.445.445 0 00.027-.391l-.292-.718.807-.678.656.412a.44.44 0 00.39.042l.872-.318a.445.445 0 00.271-.281l.238-.739h1.054l.238.739c.043.13.143.234.271.28l.873.319a.441.441 0 00.39-.042l.656-.412.807.678-.292.718a.445.445 0 00.027.39l.465.805c.069.12.189.2.325.218l.767.107.184 1.038-.686.362a.445.445 0 00-.23.316l-.161.915a.444.444 0 00.108.377l.52.575-.527.913-.757-.163a.448.448 0 00-.38.094l-.712.597z"></path>
                <path d="M15.38 14.42a3.286 3.286 0 00-3.282 3.282 3.286 3.286 0 003.281 3.282 3.286 3.286 0 003.282-3.282 3.286 3.286 0 00-3.282-3.282zm0 5.674a2.394 2.394 0 01-2.392-2.392 2.394 2.394 0 012.392-2.391 2.394 2.394 0 012.39 2.391 2.394 2.394 0 01-2.39 2.392zM8.688 7.327h13.389a.445.445 0 100-.891H8.687a.446.446 0 000 .891zM22.522 9.895c0-.246-.2-.445-.445-.445H8.687a.446.446 0 000 .89h13.39c.246 0 .446-.199.446-.445z"></path>
                <path d="M25.962 23.04V4.806A3.61 3.61 0 0022.356 1.2H8.406a3.61 3.61 0 00-3.605 3.606v20.618a3.604 3.604 0 003.602 3.606H20.128c.12 0 .234-.048.318-.134l5.39-5.505a.54.54 0 00.127-.35zM8.257 28.135a2.715 2.715 0 01-2.566-2.711V4.806c0-1.472 1.243-2.715 2.715-2.715h13.95a2.718 2.718 0 012.715 2.715v17.791h-3.225a2.206 2.206 0 00-2.204 2.204v3.34H8.402c-.046-.002-.09-.001-.145-.005zm12.277-.602v-2.732c0-.724.59-1.314 1.313-1.314h2.649l-3.962 4.046z"></path>
            </g>
        </svg>
    );
}

export default EnterpriseContent;
