import React from "react";

function Moving() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19622"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19622)">
                <path d="M22.785 15.8h-5.43a.6.6 0 01-.609-.61V10.9c0-.212.133-.424.318-.53l2.648-1.457a.526.526 0 01.557 0l2.754 1.457a.636.636 0 01.318.53v4.29c.053.345-.239.61-.556.61zm-4.82-1.192h4.21V11.27l-2.145-1.14-2.066 1.113v3.364z"></path>
                <path d="M27.896 6H12.243a.6.6 0 00-.609.61v3.919H7.026a.565.565 0 00-.45.212l-3.444 4.026c-.105.105-.132.238-.132.397v6.17a.6.6 0 00.61.61h.529a3.961 3.961 0 01.08-2.649v-3.92l3.124-3.654h4.318v7.813c.238.768.238 1.589 0 2.383h7.521a3.618 3.618 0 01-.185-1.192h-6.171V7.219h14.46v13.508h-.476c0 .424-.08.82-.186 1.192h1.272a.6.6 0 00.609-.61V6.61a.6.6 0 00-.61-.609z"></path>
                <path d="M7.846 17.574c-2.278 0-3.76 2.33-2.913 4.343a3.14 3.14 0 002.913 1.934 3.142 3.142 0 003.152-3.152c0-1.722-1.43-3.125-3.152-3.125zm1.51 4.343a1.908 1.908 0 01-3.02 0c-1.006-1.271-.106-3.152 1.51-3.152 1.642 0 2.516 1.907 1.51 3.152zM22.89 17.574c-2.279 0-3.762 2.33-2.914 4.343 1.086 2.57 4.74 2.57 5.8 0 .848-2.04-.636-4.343-2.887-4.343zm1.509 4.343c-1.166 1.457-3.47.61-3.47-1.218 0-1.06.874-1.934 1.934-1.934 1.642 0 2.542 1.907 1.536 3.152z"></path>
            </g>
        </svg>
    );
}

export default Moving;
