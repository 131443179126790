import React from "react";

function AutoTransportation() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19629"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19629)">
                <path d="M29.415 11.125h-.74V9.033h-2.767v-1.95c0-1.285-1.173-2.33-2.616-2.33h-5.198l1.634-1.633a.58.58 0 10-.82-.82l-2.275 2.276a.581.581 0 00-.118.177h-2.79l1.633-1.633a.58.58 0 10-.82-.82l-2.275 2.276a.583.583 0 00-.118.177H8.698c-1.442 0-2.615 1.045-2.615 2.33v1.95H3.315v2.092h-.74a.58.58 0 00-.579.58v6.018c0 .32.26.579.58.579h2.638c.32 0 .58-.26.58-.58v-6.018a.58.58 0 00-.58-.579h-.74v-.933h1.61v15.544h1.834v3.122h4.376v-3.122h7.402v3.122h4.377v-3.122h1.834V10.19h1.61v.933h-.74a.58.58 0 00-.58.58v6.018c0 .32.26.58.58.58h2.638c.32 0 .58-.26.58-.58v-6.018a.58.58 0 00-.58-.58v.001zm-24.78 1.159v4.86h-1.48v-4.86h1.48zm2.607 5.699V10.19h17.507v8.368H7.242v-.576zM8.699 5.912h14.593c.803 0 1.457.524 1.457 1.17v1.951H7.242V7.082c0-.646.654-1.17 1.457-1.17zM11.136 27.7h-2.06v-1.964h2.06V27.7zm11.779 0h-2.06v-1.964h2.06V27.7zM7.242 24.578v-4.86H24.75v4.86H7.241zm21.594-7.434h-1.48v-4.86h1.48v4.86z"></path>
                <path d="M10.54 22.164a.74.74 0 11-1.481 0 .74.74 0 011.48 0zM12.567 22.164a.74.74 0 11-1.48 0 .74.74 0 011.48 0zM20.902 22.164a.74.74 0 11-1.48 0 .74.74 0 011.48 0zM22.93 22.164a.74.74 0 11-1.48 0 .74.74 0 011.48 0z"></path>
            </g>
        </svg>
    );
}

export default AutoTransportation;
