import React from "react";

function Forklifting() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19539"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g
                fill="#000"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_0_19539)"
            >
                <path d="M17.41 2.4a.584.584 0 00-.483.237.641.641 0 00-.105.547l5.108 20.354c.04.16.14.296.277.38.137.082.3.104.453.06l5.697-1.631a.596.596 0 00.364-.289c.08-.143.1-.314.059-.474a.615.615 0 00-.282-.376.57.57 0 00-.454-.055l-5.12 1.466L17.97 2.87a.6.6 0 00-.559-.47zM6.57 6a.63.63 0 00-.546.614v9.105H4.216c-.98 0-1.818.784-1.818 1.782v7.954c0 .693.415 1.29.99 1.582A2.709 2.709 0 005.9 28.77c1.058 0 1.974-.627 2.42-1.533h5.82c.775.947 1.937 1.562 3.239 1.562 2.32 0 4.218-1.928 4.218-4.293a4.333 4.333 0 00-1.252-3.057l-.01-1.15c0-.821-.582-1.573-1.393-1.725L15.807 6.45a.622.622 0 00-.584-.45H6.57zm.659 1.227h7.533l2.92 11.319-3.363-.01-2.439-2.559a.842.842 0 00-.612-.258h-4.04V7.227zm-3.013 9.718h6.911l2.505 2.626c.111.12.271.19.433.192l4.567.01h.01c.373 0 .49.165.49.526v.297a4.144 4.144 0 00-1.752-.383c-2.323 0-4.219 1.93-4.219 4.293 0 .53.1 1.035.273 1.505H8.613c0-1.519-1.223-2.76-2.712-2.76-.08 0-.152.006-.207.01h-.01a2.717 2.717 0 00-2.08 1.303V17.5c0-.29.254-.555.612-.555zM17.38 21.44c.567 0 1.099.152 1.553.431l.01.01.037.028a3.08 3.08 0 011.413 2.598c0 1.701-1.345 3.066-3.014 3.066-1.02 0-1.909-.519-2.457-1.303l-.066-.096a3.085 3.085 0 01-.49-1.668c0-1.702 1.34-3.066 3.013-3.066zM5.9 24.478c.838 0 1.507.678 1.507 1.533 0 .851-.67 1.534-1.507 1.534-.745 0-1.35-.536-1.478-1.246a1.515 1.515 0 011.356-1.811c.048-.003.085-.01.122-.01z"></path>
            </g>
        </svg>
    );
}

export default Forklifting;
