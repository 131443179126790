import React from "react";

function Legal() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="35"
            fill="none"
            viewBox="0 0 38 35"
        >
            <path
                fill="#1693F0"
                fillRule="evenodd"
                d="M36.878 11.25a.625.625 0 00.3-1.175l-18.125-10a.636.636 0 00-.6 0l-18.125 10a.625.625 0 00.3 1.175h36.25zm-18.125-7.5a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm18.126 27.5h-1.25v-1.875a.625.625 0 00-.625-.625h-32.5a.625.625 0 00-.625.625v1.875H.629a.625.625 0 00-.625.625v2.5A.625.625 0 00.629 35h36.25a.625.625 0 00.625-.625v-2.5a.625.625 0 00-.625-.625zM20.64 13.843L21.729 27.5h-5.952l1.089-13.656-.538-1.344h4.85l-.538 1.344zm6.937-1.343l.537 1.344L27.027 27.5h5.952L31.89 13.844l.538-1.344h-4.85zM9.39 13.844L10.479 27.5H4.528l1.088-13.656-.538-1.344h4.85l-.537 1.344zM1.35 33.25a.75.75 0 01.75-.75h21.612a.75.75 0 010 1.5H2.1a.75.75 0 01-.75-.75zm24.55-.75a.75.75 0 000 1.5h.963a.75.75 0 000-1.5H25.9z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Legal;
