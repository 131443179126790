import React from "react";

function TextMassageMarketing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19767"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19767)">
                <path
                    fill="#000"
                    d="M23.738 12.732a2.665 2.665 0 001.16-1.575 2.667 2.667 0 00-.267-2.037 2.687 2.687 0 00-3.575-1.032l-2.41-4.175A2.38 2.38 0 0017.192 2.8a2.38 2.38 0 00-1.817.237A2.38 2.38 0 0014.26 4.49c-.125.467-.108.948.045 1.393-1.145 2.885-3.569 5.828-6.858 8.338a.566.566 0 00-.598-.02l-3.076 1.776a4.459 4.459 0 00-2.087 2.727 4.46 4.46 0 00.444 3.405 4.492 4.492 0 006.133 1.644l.176-.102 3.392 4.429a1.31 1.31 0 001.075.526c.333 0 .64-.12.893-.348l.934-.848c.52-.472.591-1.249.166-1.807l-3.19-4.19c3.79-1.568 7.515-2.181 10.562-1.734a2.401 2.401 0 003 .498 2.397 2.397 0 00.876-3.271l-2.41-4.175zm-.087-3.046c.207.36.262.778.155 1.178a1.541 1.541 0 01-.635.887L21.623 9.07a1.555 1.555 0 012.028.617zM6.025 23.223a3.36 3.36 0 01-2.914-1.678 3.333 3.333 0 01-.33-2.547 3.334 3.334 0 011.56-2.04l2.585-1.493 3.358 5.816L7.7 22.774a3.336 3.336 0 01-1.674.45zm7.949 3.35l-.934.848a.19.19 0 01-.161.054.187.187 0 01-.148-.082l-3.303-4.31 1.48-.855L14 26.29a.21.21 0 01-.026.284zm-2.869-6.133l-3.04-5.265c3.167-2.402 5.585-5.208 6.921-8.021l6.526 11.303c-3.104-.249-6.743.442-10.407 1.983zm13.601-1.24a1.265 1.265 0 01-1.725-.463L15.478 5.742a1.265 1.265 0 011.088-1.893c.11 0 .222.015.332.044.328.087.6.296.768.586l7.502 12.994c.348.604.14 1.378-.462 1.726zm.615-10.477a.565.565 0 01.207-.773L27.8 6.638a.565.565 0 11.566.98L26.094 8.93a.564.564 0 01-.773-.207zm-2.618-3.644l1.383-2.396a.566.566 0 11.98.566l-1.383 2.395a.566.566 0 01-.98-.565zm7.797 7.167a.566.566 0 01-.565.566h-2.766a.566.566 0 010-1.131h2.766c.312 0 .565.253.565.566z"
                ></path>
            </g>
        </svg>
    );
}

export default TextMassageMarketing;
