import React from "react";

function Antivirus() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19579"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19579)">
                <path
                    fill="#000"
                    d="M14.993 4.686a4.81 4.81 0 00-1.06.126l-.795-3.053c-.103-.387-.518-.629-.928-.532-.41.097-.666.489-.563.876l.86 3.314c-1.137.745-1.885 1.988-1.885 3.391 0 .533.113 1.04.307 1.505a8.72 8.72 0 00-2.63 2.603l-2.131-1.268-1.44-4.016a.781.781 0 00-.974-.455c-.4.131-.62.542-.482.92l1.527 4.257c.057.16.17.29.318.383l2.496 1.485c-.39.934-.6 1.94-.61 2.976H4.41l-.605-.847a.796.796 0 00-1.066-.199.7.7 0 00-.21 1.007l.835 1.17c.144.204.385.32.636.32h3.11a9.506 9.506 0 00.995 2.947l-1.327.32a.746.746 0 00-.538.468l-1.523 4.258c-.138.377.082.794.482.92a.865.865 0 00.246.038.76.76 0 00.728-.494l1.389-3.885 1.373-.329c1.461 1.945 3.613 3.344 5.971 3.605.03 0 .061.005.092.005s.062 0 .093-.005c2.337-.261 4.469-1.63 5.93-3.547l1.163.281 1.655 3.914c.118.286.41.455.713.455.097 0 .19-.014.287-.053.394-.15.584-.57.43-.943l-1.804-4.258a.76.76 0 00-.523-.436l-1.081-.26a9.292 9.292 0 001.025-3.005h3.111a.792.792 0 00.64-.32l.831-1.17a.7.7 0 00-.21-1.007.796.796 0 00-1.066.198l-.605.847h-2.593a8.055 8.055 0 00-.651-3.063l2.265-1.403a.71.71 0 00.292-.339l1.804-4.257c.159-.373-.036-.794-.43-.944-.395-.15-.841.034-1 .407l-1.712 4.044-1.922 1.19a8.687 8.687 0 00-2.578-2.52 3.9 3.9 0 00.308-1.505c0-1.403-.748-2.642-1.886-3.391l.866-3.315c.102-.387-.149-.783-.564-.875-.41-.097-.83.14-.928.532l-.794 3.053a4.505 4.505 0 00-1.06-.126l-.01.005zm6.463 12.634c0 3.769-2.952 7.286-6.463 7.731-3.51-.445-6.458-3.962-6.458-7.731 0-2.313 1.195-4.452 3.204-5.763a4.47 4.47 0 003.254 1.384 4.47 4.47 0 003.255-1.384c2.01 1.311 3.208 3.455 3.208 5.763zm-6.463-5.835c-1.563 0-2.834-1.2-2.834-2.671 0-1.471 1.271-2.676 2.834-2.676 1.563 0 2.835 1.2 2.835 2.676 0 1.475-1.272 2.67-2.835 2.67z"
                ></path>
            </g>
        </svg>
    );
}

export default Antivirus;
