import React from "react";

function CarWarranty() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" clipPath="url(#clip0_0_19462)">
                <path d="M23.864 12.419c.416.485.709.91 1.086 1.245.455.404.755.831.734 1.456-.06 1.895-.105 3.79-.16 5.686-.014.455-.052.909-.069 1.363-.029.715-.307.992-1.028.998h-1.684c-.889 0-1.003-.086-1.25-.942H7.335c-.347.926-.369.942-1.37.942H4.281c-.561 0-.898-.26-.916-.81-.082-2.489-.506-4.972-.208-7.469.042-.354.266-.734.513-1.006.41-.451.95-.796 1.084-1.473-.394-.085-.8-.154-1.195-.262-.606-.166-.78-.55-.523-1.111.27-.589.79-.857 1.394-.96.443-.07.89-.099 1.339-.089.265 0 .385-.088.52-.303.447-.723.902-1.444 1.407-2.13.423-.575 1.003-.835 1.777-.827 3.303.034 6.607.034 9.913 0 .714-.006 1.266.231 1.662.762.52.694.994 1.422 1.45 2.157.152.246.295.329.59.346.604.027 1.204.11 1.792.245.467.115.79.471.946.932.162.483-.022.809-.535.966-.214.066-.432.12-.651.166-.2.04-.402.063-.777.118zm1.305-.903l.053-.206c-.248-.17-.48-.43-.748-.486a6.876 6.876 0 00-1.611-.123c-.404.014-.621-.074-.832-.44-.47-.816-1.007-1.602-1.546-2.373-.246-.355-.615-.488-1.08-.485-3.303.017-6.606.023-9.912 0-.56 0-.981.17-1.273.592a33.018 33.018 0 00-1.473 2.308c-.193.332-.385.428-.757.402a6.604 6.604 0 00-1.385.032c-.418.06-.832.213-.987.801.685.073 1.333.134 1.978.219.14.018.36.103.378.188.029.132-.06.314-.151.438-.147.202-.345.367-.505.559-.36.43-.683.893-1.074 1.293-.27.283-.454.545-.442.942.052 1.755.082 3.512.14 5.267.023.67.105 1.337.161 2.025h2.4c.024-.084.043-.17.058-.255.066-.723.065-.705.785-.704 4.634.008 9.268.008 13.903 0 .881 0 .881-.02 1.018.83a.5.5 0 00.066.13h2.381c.023-.428.05-.832.065-1.236.072-2.037.146-4.073.206-6.11.005-.194-.018-.47-.142-.573-.643-.554-1.195-1.168-1.589-1.916-.034-.065-.132-.095-.17-.162a8.341 8.341 0 01-.281-.536c.18-.065.357-.162.544-.188.608-.09 1.216-.157 1.822-.233z"></path>
                <path d="M.009 7.56c0-.378-.02-.754 0-1.131.056-.961.728-1.644 1.7-1.734C5.873 4.311 9.64 2.887 13.051.54c1.066-.733 1.663-.718 2.744.027C19.18 2.9 22.922 4.301 27.046 4.693c1.065.1 1.757.813 1.752 1.876a29.252 29.252 0 01-1.216 8.308c-.07.235-.146.497-.46.423-.344-.079-.29-.343-.21-.605a27.711 27.711 0 001.178-8.027c0-.848-.36-1.232-1.22-1.319-4.277-.412-8.133-1.908-11.627-4.333-.649-.45-1.026-.462-1.654-.017a24.323 24.323 0 01-11.142 4.31c-.206.026-.416.024-.622.048-.75.087-1.105.481-1.112 1.219a27.708 27.708 0 001.122 7.94c.076.265.314.634-.116.764-.473.143-.469-.32-.549-.592A29.524 29.524 0 01.01 7.56zM14.424 30c-.473-.154-.942-.235-1.336-.448a24.39 24.39 0 01-6.026-4.548c-.077-.079-.192-.155-.213-.249-.028-.136-.038-.364.038-.413.11-.072.355-.081.455-.007.265.199.481.458.718.692 1.558 1.533 3.323 2.799 5.232 3.877.745.422 1.48.416 2.23-.006a25.105 25.105 0 005.64-4.28c.187-.188.385-.602.727-.298.364.323-.037.554-.24.764a23.881 23.881 0 01-5.832 4.412c-.423.23-.915.335-1.393.504z"></path>
                <path d="M14.397 12.018H7.71c-.803 0-1.002-.315-.61-.98.48-.815.972-1.622 1.45-2.439.235-.403.566-.621 1.047-.621h9.566c.498 0 .848.209 1.094.633.48.83.986 1.65 1.47 2.481.348.597.15.929-.548.93-2.262 0-4.523 0-6.783-.004zm-6.646-.72h13.3c-.317-.536-.659-1-.88-1.508-.375-.86-.937-1.234-1.937-1.209-2.849.074-5.7.047-8.55.078a.71.71 0 00-.503.27c-.483.749-.93 1.527-1.431 2.368zM14.403 16.824h4.908c.654 0 .813.202.687.848-.33 1.67-.104 1.458-1.791 1.46-2.774.007-5.546 0-8.32 0-.733 0-.803-.058-.947-.768-.053-.26-.103-.523-.154-.784-.096-.506.087-.745.617-.748 1.668-.007 3.335 0 5.005 0l-.005-.008zm4.879.699H9.52c.072.942.072.936.94.936 2.626 0 5.253.006 7.88 0 .98 0 .737.157.962-.807a.518.518 0 00-.021-.13zM6.362 18.285a1.763 1.763 0 01-1.238-.48 1.69 1.69 0 01-.53-1.197 1.699 1.699 0 01.512-1.227 1.801 1.801 0 011.249-.512 1.78 1.78 0 011.227.507c.326.32.51.752.513 1.204a1.664 1.664 0 01-.504 1.206 1.735 1.735 0 01-1.23.5zm1.03-1.683a1.017 1.017 0 00-.297-.747 1.059 1.059 0 00-.752-.316c-.545 0-1.042.481-1.058 1.025a1.017 1.017 0 00.31.739 1.059 1.059 0 00.755.303 1.036 1.036 0 00.737-.286.995.995 0 00.304-.716v-.002zM22.468 18.287a1.766 1.766 0 01-1.238-.482 1.666 1.666 0 01-.39-1.864 1.73 1.73 0 01.381-.563 1.772 1.772 0 011.251-.51c.46.01.898.195 1.221.516.323.32.507.751.511 1.202 0 .45-.184.882-.509 1.201a1.76 1.76 0 01-1.227.5zm0-.681a1.06 1.06 0 00.74-.29c.199-.187.313-.444.319-.714.008-.559-.505-1.069-1.068-1.063a1.07 1.07 0 00-.732.307c-.194.191-.304.45-.307.719a.98.98 0 00.292.741 1.023 1.023 0 00.751.3h.005z"></path>
            </g>
            <defs>
                <clipPath id="clip0_0_19462">
                    <path fill="#fff" d="M0 0H28.8V30H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CarWarranty;
