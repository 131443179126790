import React from "react";

const InsuranceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="35"
            fill="none"
            viewBox="0 0 32 35"
        >
            <path
                fill="#1693F0"
                fillRule="evenodd"
                d="M15.796 35C1.436 28.74 0 18.305 0 6.522c0-8.74 31.635-8.652 31.635 0 0 11.782-1.436 22.217-15.84 28.478zm-.218-6.913a.913.913 0 01.783 1.479.926.926 0 01-1.349.087c-.609-.566-.26-1.566.566-1.566zM4.613 7.174c-.044-1.217 1.784-1.26 1.827-.043.13 7.913 1.306 14.825 7.702 19.695.957.739-.174 2.217-1.131 1.478-6.832-5.217-8.268-12.652-8.398-21.13zm11.183 25c11.488-5.392 13.271-13.652 13.271-25.652 0-.087 0-.13-.043-.218-.348-1.174-2.829-2.13-3.83-2.434-2.915-.957-6.309-1.305-9.398-1.305-3.046 0-6.397.348-9.356 1.261-1.044.348-3.481 1.304-3.83 2.435-.043.087-.043.174-.043.26 0 12 1.74 20.262 13.228 25.653zm0 1.217c12.315-5.609 14.36-14.217 14.36-26.87 0-.173 0-.347-.088-.52-.478-1.653-3.09-2.74-4.525-3.218-3.046-.957-6.571-1.305-9.747-1.305-3.133 0-6.658.348-9.704 1.305-1.436.478-4.047 1.521-4.57 3.174a2.546 2.546 0 00-.086.565c0 12.652 2.045 21.26 14.36 26.869z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default InsuranceIcon;
