import React from "react";

function WideFormatPrinters() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19523"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19523)">
                <path d="M14.704 11.716c-.593 0-1.075.482-1.075 1.075a1.076 1.076 0 002.15 0c0-.593-.482-1.075-1.075-1.075zm0 1.563a.489.489 0 110-.978.489.489 0 010 .978z"></path>
                <path d="M27.828 12.145c-.145-2.072-2.043-3.69-4.39-3.77V6.578h2.855c.57 0 1.053-.517 1.122-1.202a.293.293 0 00-.243-.318.293.293 0 00-.335.22c-.055.228-.203.387-.361.387-.21 0-.386-.258-.386-.564 0-.379.266-.7.542-.508a.294.294 0 00.394-.053.292.292 0 00-.02-.396 1.013 1.013 0 00-.713-.307H23.17a.289.289 0 00.04-.302c-.323-.7-.955-1.134-1.65-1.134H11.58c-.817 0-1.512.602-1.763 1.436H6.29c-.82 0-1.488.615-1.488 1.37 0 .757.668 1.371 1.488 1.371h3.428v.753c0 .161.131.292.294.292h3.559v.747H6.744c-2.426 0-4.415 1.651-4.566 3.774a.296.296 0 00-.014.09v5.357c0 .161.131.292.294.292h1.46v6.682h-.635a.293.293 0 100 .586h4.046a.293.293 0 100-.586H5.811v-6.682h3.917v9.425c0 .161.132.293.294.293h9.25a.293.293 0 00.293-.293v-9.425h4.63v6.682h-1.519a.293.293 0 100 .585h4.047a.293.293 0 100-.585h-.635v-6.682h1.46a.293.293 0 00.294-.293v-5.357a.288.288 0 00-.014-.087zM9.717 4.516v1.475H6.289c-.496 0-.9-.352-.9-.785 0-.433.404-.785.9-.785h3.432c-.001.032-.004.063-.004.095zm11.563-1.53h.278c.464 0 .892.303 1.117.792.01.023.027.04.042.058h-2.634c.177-.494.644-.85 1.197-.85zm-1.276 1.436h5.687c-.118.19-.188.424-.188.678 0 .36.14.68.36.891h-5.859v-1.57zm0 2.155h2.847v1.79h-8.693v-.745h5.534a.29.29 0 00.218-.101.29.29 0 00.094-.212v-.732zm-9.7.46v-2.52c0-.094.01-.184.024-.272a.29.29 0 00.024-.116v-.002c.145-.655.638-1.141 1.23-1.141h8.346a1.859 1.859 0 00-.512 1.28v2.77h-9.111zm-5.08 17.527h-.718v-6.682h.717v6.682zm13.754 2.45h-8.661V15.332h8.661v11.684zm6.523-2.45h-.718v-6.682h.718v6.682zm1.753-7.267h-7.688V15.33h2.857a.293.293 0 100-.585H6.872a.293.293 0 100 .585h2.857v1.966H2.752v-4.992a.3.3 0 00.01-.058c.092-1.847 1.842-3.293 3.983-3.293h16.517c2.141 0 3.892 1.446 3.984 3.293.001.02.004.038.009.056v4.994z"></path>
            </g>
        </svg>
    );
}

export default WideFormatPrinters;
