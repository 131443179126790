import React from "react";

function WebsiteBuilders() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19590"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19590)">
                <path d="M4.381 5.453a.613.613 0 00-.627.627c0 .359.299.628.627.628a.613.613 0 00.628-.628.613.613 0 00-.628-.627zM6.116 5.453a.613.613 0 00-.628.627c0 .359.27.628.628.628a.613.613 0 00.627-.628.613.613 0 00-.627-.627zM16.394 21.883c.24.508.837.747 1.345.538.507-.21.746-.837.537-1.345l-1.673-3.794 3.316.09-7.379-6.84-.06 10.067 2.27-2.48 1.644 3.764z"></path>
                <path d="M26.849 3.6H3.546c-.985 0-1.792.807-1.792 1.793v18.82c0 .986.807 1.793 1.792 1.793H26.85c.985 0 1.792-.807 1.792-1.793V5.393c0-.986-.807-1.793-1.793-1.793zm.597 20.613a.6.6 0 01-.597.598H3.547a.6.6 0 01-.598-.597V8.41h24.497v15.803zm0-16.998H2.95V5.393a.6.6 0 01.598-.598h23.302a.6.6 0 01.597.598v1.822z"></path>
                <path d="M7.846 5.453a.613.613 0 00-.627.627c0 .359.269.628.627.628a.613.613 0 00.628-.628.613.613 0 00-.628-.627z"></path>
                <path d="M4.381 6.707a.631.631 0 01-.627-.627c0-.359.269-.628.627-.628.359 0 .628.269.628.628a.613.613 0 01-.628.627zm1.733 0a.613.613 0 01-.627-.627c0-.359.268-.628.627-.628s.628.269.628.628a.613.613 0 01-.628.627zm1.733 0a.613.613 0 01-.628-.627c0-.359.269-.628.628-.628.358 0 .627.269.627.628a.613.613 0 01-.627.627z"></path>
            </g>
        </svg>
    );
}

export default WebsiteBuilders;
