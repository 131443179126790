import React, { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";
export default function Text({
    type,
    style,
    className,
    itemProp,
    children,
}: {
    type?: "mainTitle" | "title" | undefined;
    style?: { [x: string]: string };
    className?: string;
    itemProp?: string;
    children: ReactNode;
}): ReactElement {
    switch (type) {
        case "mainTitle":
            return itemProp ? (
                <h1
                    className={`${className ?? ""} ${
                        styles.mainTitle
                    } font-extrabold text-2xl  lg1048:text-4xl`}
                    style={style}
                    itemProp={itemProp}
                >
                    {children}
                </h1>
            ) : (
                <h1
                    className={`${className ?? ""} ${
                        styles.mainTitle
                    } font-extrabold text-2xl  lg1048:text-4xl`}
                    style={style}
                >
                    {children}
                </h1>
            );
        case "title":
            return (
                <h2
                    className={`${className ?? ""} ${
                        styles.title
                    } font-extrabold  text-2xl  lg1048:text-4xl`}
                    style={style}
                >
                    {children}
                </h2>
            );
        default: // paragraph
            return (
                <p className={`${className ?? ""}`} style={style}>
                    {children}
                </p>
            );
    }
}
