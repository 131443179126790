import React from "react";

function CreditCardProcessing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19771"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19771)">
                <path d="M5.266 27.184h11.946c.918 0 1.665-.746 1.665-1.664v-1.909h7.96a1.55 1.55 0 001.549-1.549V11.945a1.55 1.55 0 00-1.55-1.55h-7.96v-6.33c0-.918-.746-1.665-1.664-1.665H5.266c-.918 0-1.664.747-1.664 1.664V25.52c0 .917.746 1.664 1.664 1.664zM27.353 15.77h-8.476v-1.202h8.476v1.202zm-.516 6.81h-7.96v-5.777h8.476v5.26a.517.517 0 01-.516.516zm0-11.15c.284 0 .516.232.516.516v1.59h-8.476v-2.106h7.96zM4.635 4.065c0-.348.283-.632.631-.632h11.946c.349 0 .632.284.632.632v21.456a.632.632 0 01-.631.63H5.267a.632.632 0 01-.632-.63V4.064z"></path>
                <path d="M6.188 12.671h10.105a.516.516 0 00.516-.516V4.982a.516.516 0 00-.516-.516H6.188a.516.516 0 00-.516.516v7.173c0 .285.23.516.516.516zm.516-7.172h9.072v6.14H6.704v-6.14zM6.188 16.004h.764a.516.516 0 100-1.033h-.764a.516.516 0 000 1.033zM10.065 14.971h-.764a.516.516 0 000 1.033h.764a.516.516 0 000-1.033zM13.178 14.971h-.763a.516.516 0 100 1.033h.763a.516.516 0 000-1.033zM16.292 14.971h-.764a.516.516 0 000 1.033h.764a.516.516 0 100-1.033zM6.188 18.77h.764a.516.516 0 100-1.033h-.764a.516.516 0 000 1.033zM10.065 17.737h-.764a.516.516 0 000 1.033h.764a.516.516 0 000-1.033zM13.178 17.737h-.763a.516.516 0 100 1.033h.763a.516.516 0 000-1.033zM16.292 17.737h-.764a.516.516 0 000 1.033h.764a.516.516 0 100-1.033zM6.188 21.536h.764a.516.516 0 100-1.032h-.764a.516.516 0 000 1.032zM10.065 20.503h-.764a.516.516 0 000 1.033h.764a.516.516 0 000-1.033zM13.178 20.503h-.763a.516.516 0 100 1.033h.763a.516.516 0 000-1.033zM16.292 20.503h-.764a.516.516 0 000 1.033h.764a.516.516 0 100-1.033zM6.188 24.303h.764a.516.516 0 100-1.033h-.764a.516.516 0 000 1.033zM10.065 23.27h-.764a.516.516 0 000 1.032h.764a.516.516 0 000-1.032zM13.178 23.27h-.763a.516.516 0 100 1.032h.763a.516.516 0 000-1.032zM16.292 23.27h-.764a.516.516 0 000 1.032h.764a.516.516 0 100-1.032z"></path>
            </g>
        </svg>
    );
}

export default CreditCardProcessing;
