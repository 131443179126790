import React from "react";

function Medicare() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19659"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19659)">
                <path d="M24.256 8.317h-1.768V6.548A2.948 2.948 0 0019.54 3.6h-7.075a2.948 2.948 0 00-2.948 2.948v1.769H7.75A2.948 2.948 0 004.8 11.264v11.791a2.948 2.948 0 002.948 2.948h16.507a2.948 2.948 0 002.948-2.948v-11.79a2.948 2.948 0 00-2.948-2.948zm-13.56-1.769a1.769 1.769 0 011.77-1.769h7.074a1.77 1.77 0 011.768 1.769v1.769H10.697V6.548zm10.612 2.948v15.328H10.697V9.496h10.611zM5.98 23.056V11.263A1.77 1.77 0 017.75 9.496h1.769v15.328h-1.77a1.769 1.769 0 01-1.768-1.769zm20.045 0a1.769 1.769 0 01-1.768 1.768h-1.77V9.496h1.77a1.769 1.769 0 011.768 1.768v11.791z"></path>
                <path d="M12.469 17.75h2.947v2.947h1.18V17.75h2.947V16.57h-2.947v-2.947h-1.18v2.947H12.47v1.18z"></path>
            </g>
        </svg>
    );
}

export default Medicare;
