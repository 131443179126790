import React from "react";

function TaxDebtRelief() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19500"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19500)">
                <path d="M25.928 25.56l-2.352-13.35c-.175-1-.988-1.727-1.924-1.727H19.1V8.356a.427.427 0 00-.426-.425h-1.667a3.187 3.187 0 001.03-2.34 3.192 3.192 0 00-6.382 0c0 .923.4 1.757 1.03 2.34h-1.668a.427.427 0 00-.426.425v2.127H8.464c-.94 0-1.748.727-1.923 1.727L4.19 25.56a1.33 1.33 0 00.285 1.089c.225.268.548.421.884.421h19.396c.336 0 .66-.153.885-.421.25-.298.353-.698.285-1.089h.004zM12.501 5.59c0-1.289 1.051-2.34 2.34-2.34 1.29 0 2.34 1.051 2.34 2.34 0 1.29-1.05 2.34-2.34 2.34-1.289 0-2.34-1.05-2.34-2.34zm-1.063 3.19h6.807v1.703h-6.807V8.78zm13.554 17.324a.306.306 0 01-.234.12H5.35a.303.303 0 01-.234-.12.477.477 0 01-.098-.395l2.353-13.35c.106-.591.561-1.025 1.085-1.025h13.192c.524 0 .983.43 1.085 1.025l2.353 13.35a.493.493 0 01-.098.395h.004z"></path>
                <path d="M16.153 17.196a.427.427 0 00.85 0 2.135 2.135 0 00-1.7-2.085v-.608a.427.427 0 00-.426-.425.427.427 0 00-.425.425v.608a2.127 2.127 0 000 4.17v2.518a1.272 1.272 0 01-.851-1.2.427.427 0 00-.426-.425.427.427 0 00-.425.425c0 1.026.732 1.885 1.702 2.085v.608c0 .234.191.426.425.426a.427.427 0 00.426-.426v-.608a2.127 2.127 0 000-4.17v-2.518c.493.175.85.643.85 1.2zm-2.552 0c0-.553.357-1.021.85-1.2v2.395a1.272 1.272 0 01-.85-1.2v.005zm2.552 3.403a1.28 1.28 0 01-.85 1.2v-2.395c.493.174.85.642.85 1.2v-.005z"></path>
            </g>
        </svg>
    );
}

export default TaxDebtRelief;
