import React from "react";

function BestFloorCleaningMachines() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19479"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19479)">
                <path
                    fill="#000"
                    d="M4.323 19.814V3.967c0-.391.316-.707.707-.707a12.9 12.9 0 013.428.466l1.71-.903a3.708 3.708 0 013.694.146l2.444 1.535a2.277 2.277 0 002.333.055 3.653 3.653 0 013.678.03 3.773 3.773 0 011.877 3.292v2.46h1.57a.707.707 0 110 1.414h-1.57V22.49h1.787a.71.71 0 01.677.502l1.2 3.984a.71.71 0 01-.678.913h-7.397c-.527 0-.853-.522-.642-1.003l1.174-3.894a.704.704 0 01.678-.502h1.786V11.755h-1.801a.707.707 0 110-1.415h1.801V7.881c0-.868-.436-1.64-1.174-2.067a2.262 2.262 0 00-2.268-.02 3.695 3.695 0 01-3.784-.09L13.11 4.162a2.295 2.295 0 00-2.278-.09l-.562.296c4.632 2.062 7.879 6.77 7.879 12.239v9.514a.707.707 0 01-.708.708H9.546a4.286 4.286 0 01-2.805 1.049c-2.393 0-4.34-1.977-4.34-4.411 0-1.516.762-2.86 1.922-3.653zm17.197 4.09l-.773 2.564h5.48l-.773-2.564H21.52zM10.63 25.42h6.103v-5.455h-1.33a.707.707 0 110-1.415h1.33v-1.23h-2.47a.707.707 0 110-1.415h2.45C16.36 9.89 11.634 5.056 5.743 4.695v14.493c.321-.076.658-.126.999-.126 2.393 0 4.34 1.977 4.34 4.411 0 .703-.165 1.36-.451 1.947zm-3.888 1.048c1.61 0 2.925-1.344 2.925-2.995s-1.31-2.996-2.925-2.996c-1.616 0-2.926 1.345-2.926 2.996 0 1.656 1.315 2.995 2.926 2.995z"
                ></path>
            </g>
        </svg>
    );
}

export default BestFloorCleaningMachines;
