import React from "react";

function InternetServiceProviders() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19599"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19599)">
                <path
                    fill="#000"
                    d="M17.317 15.428c0-1.17-.951-2.12-2.12-2.12-1.17 0-2.121.95-2.121 2.12 0 1.17.951 2.121 2.12 2.121 1.17 0 2.121-.951 2.121-2.12zm-2.12.91a.912.912 0 01-.91-.91c0-.5.41-.909.91-.909s.909.41.909.91c0 .499-.41.908-.91.908zm2.127 3.44a.605.605 0 01-.813-.278.605.605 0 01.28-.812 3.604 3.604 0 002.041-3.26 3.639 3.639 0 00-3.636-3.636 3.639 3.639 0 00-3.635 3.636c0 1.397.781 2.645 2.042 3.263a.607.607 0 01-.533 1.09 4.821 4.821 0 01-2.721-4.353 4.854 4.854 0 014.848-4.848 4.854 4.854 0 014.847 4.848 4.813 4.813 0 01-2.72 4.35zm1.2 2.455a.607.607 0 11-.533-1.09 6.317 6.317 0 003.568-5.715 6.37 6.37 0 00-6.362-6.363 6.37 6.37 0 00-6.363 6.363 6.325 6.325 0 003.57 5.717.61.61 0 01.278.812.61.61 0 01-.812.279 7.533 7.533 0 01-4.248-6.808c0-4.178 3.397-7.575 7.575-7.575 4.178 0 7.574 3.397 7.574 7.575a7.526 7.526 0 01-4.248 6.805zm1.2 2.451a.607.607 0 11-.533-1.09 9.036 9.036 0 005.095-8.166c0-5.011-4.078-9.09-9.09-9.09-5.01 0-9.089 4.079-9.089 9.09a9.045 9.045 0 005.096 8.169.606.606 0 11-.533 1.09 10.248 10.248 0 01-5.775-9.259c0-5.68 4.62-10.301 10.302-10.301 5.68 0 10.301 4.62 10.301 10.301a10.24 10.24 0 01-5.774 9.256zm8.5-9.256c0 5.011-2.799 9.498-7.304 11.704a.604.604 0 01-.812-.278.605.605 0 01.279-.812c4.087-2 6.626-6.066 6.626-10.614 0-6.514-5.303-11.816-11.817-11.816S3.38 8.914 3.38 15.428c0 4.548 2.54 8.614 6.626 10.616a.607.607 0 01-.533 1.09c-4.505-2.208-7.305-6.692-7.305-11.706C2.168 8.244 8.012 2.4 15.196 2.4s13.028 5.844 13.028 13.028z"
                ></path>
            </g>
        </svg>
    );
}

export default InternetServiceProviders;
