import React from "react";

function FleetManagementSystem() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19679"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19679)">
                <path d="M6.015 17.536a1.64 1.64 0 10.007 3.28 1.64 1.64 0 00-.007-3.28zm0 2.226a.586.586 0 11.007-1.172.586.586 0 01-.007 1.172zM18.285 17.536a1.64 1.64 0 101.643 1.643 1.646 1.646 0 00-1.643-1.643zm0 2.226a.586.586 0 11.006-1.172.586.586 0 01-.006 1.172zM14.934 19.762H9.36a.527.527 0 000 1.054h5.575a.527.527 0 100-1.054zM14.934 17.536H9.36a.527.527 0 100 1.053h5.575a.527.527 0 100-1.053z"></path>
                <path d="M27.392 9.144l-1.833-5.063A1.654 1.654 0 0024.014 3h-9.592a1.638 1.638 0 00-1.538 1.081l-1.84 5.063c-.085 0-.17.009-.252.028H7.35a1.636 1.636 0 00-1.538 1.075l-1.84 5.063a1.928 1.928 0 00-1.832 1.924v5.287a.53.53 0 00.526.527h.59v2.816a.526.526 0 00.527.526h3.35a.525.525 0 00.526-.526v-2.816h8.981v2.816a.526.526 0 00.527.526h3.35a.526.526 0 00.526-.526v-2.816h.583a.528.528 0 00.527-.527v-5.287a1.933 1.933 0 00-.035-.351h1.594v2.816a.521.521 0 00.526.526h3.343a.521.521 0 00.526-.527v-2.815h.59a.525.525 0 00.527-.527v-5.288a1.924 1.924 0 00-1.833-1.924zM13.874 4.446c.08-.234.3-.392.548-.393h9.592a.6.6 0 01.555.393l1.706 4.691H12.161l1.713-4.69zm-7.07 6.166a.584.584 0 01.547-.387h9.592c.247 0 .469.155.555.387l.274.758.386 1.053.428 1.173.38 1.054.238.653H5.097l1.706-4.691zm-.197 14.725H4.31v-2.289h2.296v2.29zm13.384 0h-2.296v-2.289h2.296v2.29zm1.11-8.104v4.761H3.193v-4.76a.879.879 0 01.878-.878h16.15a.871.871 0 01.801.526c.05.11.077.23.077.351zm5.954 1.939h-2.29v-2.29h2.29v2.29zm1.117-3.343H21.55c-.331-.316-.765-.5-1.222-.52l-.239-.66h1.917a.527.527 0 100-1.053H19.71l-.428-1.173h2.724a.527.527 0 100-1.053h-3.11l-.408-1.124a.142.142 0 00-.028-.056h8.834a.879.879 0 01.878.878v4.76z"></path>
                <path d="M25.354 11.37a1.64 1.64 0 10-.006 3.28 1.64 1.64 0 00.006-3.28zm0 2.226a.587.587 0 11-.007-1.173.587.587 0 01.007 1.173z"></path>
            </g>
        </svg>
    );
}

export default FleetManagementSystem;
