import React from "react";

function PeoEmployeeLeasing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19547"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g mask="url(#mask0_0_19547)">
                <path
                    fill="#000"
                    d="M15.206.749a5.318 5.318 0 00-5.308 5.308 5.318 5.318 0 005.308 5.308 5.318 5.318 0 005.309-5.308A5.318 5.318 0 0015.206.75zm0 1.249a4.05 4.05 0 014.06 4.06 4.05 4.05 0 01-4.06 4.058 4.05 4.05 0 01-4.059-4.059 4.05 4.05 0 014.06-4.06zm0 10.616c-2.71 0-5.162.732-6.976 1.952s-3.015 2.97-3.015 4.918v8.743c0 .327.297.624.624.624h18.735a.649.649 0 00.624-.624v-8.743c0-1.949-1.2-3.699-3.015-4.918-1.813-1.22-4.267-1.952-6.976-1.952zm0 1.25c.287 0 .57.01.85.029l-.41 1.844h-.879l-.41-1.844c.28-.019.562-.03.85-.03zm-2.088.175l.527 2.342-.927 6.761c-.026.19.04.391.176.527l1.873 1.874c.231.228.648.228.879 0l1.873-1.874a.634.634 0 00.176-.527l-.927-6.761.527-2.342c1.63.265 3.08.821 4.195 1.57 1.573 1.058 2.46 2.44 2.46 3.875v8.118H6.463v-8.118c0-1.435.886-2.817 2.459-3.874 1.114-.75 2.565-1.306 4.195-1.571zm1.688 2.947h.8l.82 6.01-1.22 1.22-1.22-1.22.82-6.01z"
                ></path>
            </g>
        </svg>
    );
}

export default PeoEmployeeLeasing;
