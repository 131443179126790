import React from "react";

function InventoryManagement() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            fill="none"
            viewBox="0 0 31 30"
        >
            <mask
                id="mask0_0_19745"
                style={{ maskType: "alpha" }}
                width="31"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30.932V30H0z"></path>
            </mask>
            <g fill="#000" mask="url(#mask0_0_19745)">
                <path d="M19.582 17.577c.027.19.19.33.381.328a.269.269 0 00.055 0l3.298-.475a.382.382 0 00.324-.432l-.23-1.565a.38.38 0 00-.43-.322l-3.3.474a.38.38 0 00-.324.433l.226 1.56zm3.127-1.664l.116.811-2.544.373-.108-.811 2.536-.373z"></path>
                <path d="M27.98 21.767l-.489-3.394a.38.38 0 00-.432-.322l-1.318.19-1.847-12.816a.392.392 0 00-.148-.252.38.38 0 00-.284-.07l-4.663.668h-.052l-3.77.543-.036.01h-.04l-4.249.618-.443-3.014a.38.38 0 00-.433-.324l-5.842.834a.381.381 0 00-.327.44l.219 1.508a.38.38 0 00.43.322l3.016-.434 1.81 12.567a4.34 4.34 0 106.222 5.13l12.35-1.78c.1-.014.19-.067.25-.148a.38.38 0 00.077-.276zM18.48 6.584l.85 5.903-1.418-.83a.38.38 0 00-.47.068l-1.127 1.196-.851-5.903 3.016-.434zm-3.77.543l.965 6.691a.381.381 0 00.653.206l1.46-1.55 1.84 1.074a.381.381 0 00.571-.38l-.965-6.692 3.957-.572 1.793 12.435-10.486 1.523a4.31 4.31 0 00-2.195-1.403l-1.55-10.763 3.956-.57zM7.974 5.794a.38.38 0 00-.43-.322l-3.017.429-.108-.762 5.09-.726 2.003 13.901a4.222 4.222 0 00-1.702.216L7.975 5.794zm3.71 20.39a3.578 3.578 0 11-1.023-7.083 3.64 3.64 0 01.514-.038 3.58 3.58 0 01.508 7.121zm3.795-3.008c.048-.383.045-.77-.01-1.152a4.378 4.378 0 00-.493-1.468l10.492-1.512 1.317-.19.381 2.639-11.687 1.683z"></path>
            </g>
        </svg>
    );
}

export default InventoryManagement;
