import React from "react";

function DownArrow({ width = 19 }: { width?: number }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height="12"
            fill="none"
            viewBox="0 0 19 12"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M.037 2.09L2.017 0 9.43 7.82 16.84 0l1.98 2.09L9.43 12 .037 2.09z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default DownArrow;
